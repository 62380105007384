export const news = [
    {
        newsNum: 1, 
        newsTitle: 
            '헬스케어 스타트업 오티톤 메디컬, 일본 시부야 스타트업 선정'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-09-25', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news1.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://www.stardailynews.co.kr/news/articleView.html?idxno=460347" target="_blank">헬스케어 스타트업 오티톤 메디컬, 일본 시부야 스타트업 선정 | 스타데일리뉴스=황규준 기자</p>
            `
    },
    {
        newsNum: 2, 
        newsTitle: 
            '오티톤 메디컬, 日 시부야 스타트업 프로그램 선정'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-09-25', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news2.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://www.goodkyung.com/news/articleView.html?idxno=247107" target="_blank">오티톤 메디컬, 日 시부야 스타트업 프로그램 선정 | 굿모닝경제=박선하 기자</p>
            `
    },
    {
        newsNum: 3, 
        newsTitle: 
            'This Device Can Tell You if Your Child’s Ear Infection Might Become Something Worse'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-09-25', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news3.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <img src="${require('@/assets/images/news4.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://geekspin.co/otiton-medical-doctor-in-home/" target="_blank">This Device Can Tell You if Your Child’s Ear Infection Might Become Something Worse | Chance Kinney</p>
            `
    },
    {
        newsNum: 4, 
        newsTitle: 
            '오티톤메디컬, "닥터 인 홈" 브랜드 공개'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-10-10', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news5.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://www.seoulfn.com/news/articleView.html?idxno=533989&replyAll=&reply_sc_order_by=I" target="_blank">오티톤메디컬, '닥터 인 홈' 브랜드 공개 | 서울파이낸스=유원상 기자</p>
            `
    },
    {
        newsNum: 5, 
        newsTitle: 
            '오티톤 메디컬, "오티톤 스마트 체온계 및 AI 기반 헬스케어 플랫폼으로 개인 건강 관리의 새로운 패러다임 제시! 현지 의료 기관·디지털 헬스케어 기업과 협력 통해 스마트 헬스케어 솔루션 확산할 것!"'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-10-10', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news6.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://m.news.nate.com/view/20241008n22263">오티톤메디컬, '오티톤 메디컬, "오티톤 스마트 체온계 및 AI 기반 헬스케어 플랫폼으로 개인 건강 관리의 새로운 패러다임 제시! 현지 의료 기관·디지털 헬스케어 기업과 협력 통해 스마트 헬스케어 솔루션 확산할 것!" | 에이빙뉴스</p>
            `
    },
    {
        newsNum: 6, 
        newsTitle: 
            '(주)오티톤 메디컬 ‘닥터 인 홈’ 2024 미국 라스베가스 무선통신 전시회 ‘MWC Las Vegas’ 참가'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-11-07', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news7.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="http://www.kdpress.co.kr/news/articleView.html?idxno=132913">(주)오티톤 메디컬 ‘닥터 인 홈’ 2024 미국 라스베가스 무선통신 전시회 ‘MWC Las Vegas’ 참가 | 데일리경제=오석주 기자</p>
            `
    },
    {
        newsNum: 7, 
        newsTitle: 
            '"스마트 체온계+비대면 진료 플랫폼, 일본 시장 먼저 갑니다"'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-11-07', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news8.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <img src="${require('@/assets/images/news9.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <img src="${require('@/assets/images/news10.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="http://www.hitnews.co.kr/news/articleView.html?idxno=58445">"스마트 체온계+비대면 진료 플랫폼, 일본 시장 먼저 갑니다" | 히트뉴스=윤구현 보건산업정책 전문기자</p>
            `
    },
    {
        newsNum: 8, 
        newsTitle: 
            '오티톤 메디컬, 요코하마 Health Tech Japan서 ‘닥터 인 홈’ 스마트 체온계 공개'
        , 
        newsWriter: 
            '닥터인홈'
        , 
        newsRdcnt: 0, 
        newsDate: '2024-11-07', 
        newsContent: 
            `
                <img src="${require('@/assets/images/news11.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
                <p>더 많은 정보를 보려면 아래 링크를 클릭하세요</p>
                <p>출처 : <a href="https://www.nbntv.co.kr/news/articleView.html?idxno=3027221">오티톤 메디컬, 요코하마 Health Tech Japan서 ‘닥터 인 홈’ 스마트 체온계 공개 | 내외경제TV=김혜인 기자</p>
            `
    },
]