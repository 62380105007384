<template>
    <div class="test2">
        <p>안녕하세요</p>


    </div>

</template>
<script>
export default {
  name: 'test2'
}
</script>
<style scoped>

</style>