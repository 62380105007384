<template>
    <div class="container">
        <!-- Heading -->
        <h1>{{ formTitle[$store.state.language] }}</h1>

        <!-- Links -->
        <ul class="links">
            <li>
                <a href="#" @click.prevent="switchTo('signin')" v-if="$store.state.language === 'ko'">로그인</a>
                <a href="#" @click.prevent="switchTo('signin')" v-if="$store.state.language === 'en'">Log in</a>
            </li>
            <li>
                <a href="#" @click.prevent="switchTo('signup')" v-if="$store.state.language === 'ko'">회원가입</a>
                <a href="#" @click.prevent="switchTo('signup')" v-if="$store.state.language === 'en'">Sign up</a>
            </li>
            <li>
                <a href="#" @click.prevent="resetForm">RESET</a>
            </li>
        </ul>

        <!-- Form -->
        <form>
            <!-- email input -->
            <div class="first-input input__block"
                :class="{ 'first-input__block': formTitle[$store.state.language] === '로그인', 'signup-input__block': formTitle[$store.state.language] === '회원가입' }">
                <input type="email" placeholder="Email" class="input" id="email" v-model="state.form.userEmail" />
            </div>
            <!-- password input -->
            <div class="input__block">
                <input type="password" placeholder="Password" class="input" id="password" v-model="state.form.userPwd" />
            </div>
            <!-- repeat password input -->
            <div class="input__block" v-if="formTitle[$store.state.language] === '회원가입' || formTitle[$store.state.language] === 'Sign up'">
                <input type="password" placeholder="Repeat password" class="input repeat__password" id="repeat__password" v-model="repeatPassword" />
            </div>
            <div class="input__block" v-if="formTitle[$store.state.language] === '회원가입' || formTitle[$store.state.language] === 'Sign up'">
                <input type="text" placeholder="Name" class="input" id="name" v-model="state.form.userName" />
            </div>
            <div class="input__block" v-if="formTitle[$store.state.language] === '회원가입' || formTitle[$store.state.language] === 'Sign up'">
                <input type="tel" placeholder="Phone Number" class="input" id="phone" v-model="state.form.userPhn" />
            </div>
            <!-- sign in button -->
            <button class="signin__btn" @click.prevent="submit()">{{ buttonText[$store.state.language] }}</button>
        </form>
        <!-- separator -->
        <div class="separator">
            <p>OR</p>
        </div>
        <!-- google button -->
        <button class="google__btn">
            <i class="fa fa-google"></i>
            Sign in with Google
        </button>
        <!-- google button -->
        <!-- <button class="github__btn">
            <i class="fa fa-github"></i>
            Sign in with GitHub
        </button> -->
    </div>
</template>

<script>
import axios from 'axios';
import { reactive } from 'vue';

export default {
    name: 'Login',
    setup() {
        const state = reactive({
            form: {
                userEmail: "",
                userPwd: ""
            }
        });
        

        const submit = () => {
            axios.post("/api/account/login", state.form)
                .then((res) => {
                    console.log(res);
                    window.alert("로그인하였습니다.");
                    // 로그인 성공 시 다른 처리 추가 가능
                })
                .catch((error) => {
                    console.error(error);
                    window.alert("로그인에 실패했습니다.");
                    // 로그인 실패 시 다른 처리 추가 가능
                });
        };
        return { state, submit };
    },
    data() {
        return {
            formTitle: {
                ko: '로그인',
                en: 'Sign in'
            },
            email: '',
            password: '',
            repeatPassword: '',
            buttonText: {
                ko: '로그인',
                en: 'Sign in'
            }
        };
    },
    methods: {
        switchTo(formType) {
            if (formType === 'signup') {
                this.formTitle = {
                    ko: '회원가입',
                    en: 'Sign up'
                };
                this.buttonText = {
                    ko: '회원가입',
                    en: 'Sign up'
                };
            } else if (formType === 'signin') {
                this.formTitle = {
                    ko: '로그인',
                    en: 'Sign in'
                };
                this.buttonText = {
                    ko: '로그인',
                    en: 'Sign in'
                };
            }
        },
        resetForm() {
            this.email = '';
            this.password = '';
            this.repeatPassword = '';
        },
    }
};
</script>
<style scoped>
.container {
    display: block;
    max-width: 680px;
    width: 80%;
    margin: 120px auto;
}

h1 {
    color: #e91e63;
    font-size: 48px;
    letter-spacing: -3px;
    text-align: center;
    margin: 120px 0 80px 0;
    transition: 0.2s linear;
}

.links {
    list-style-type: none;

    li {
        display: inline-block;
        margin: 0 20px 0 0;
        transition: 0.2s linear;

        &:nth-child(2) {
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }

        &:nth-child(3) {
            opacity: 0.6;
            float: right;

            &:hover {
                opacity: 1;
            }
        }

        a {
            text-decoration: none;
            color: #0f132a;
            font-weight: bolder;
            text-align: center;
            cursor: pointer;
        }
    }
}

form {
    width: 100%;
    max-width: 680px;
    margin: 40px auto 10px;

    .input__block {
        margin: 20px auto;
        display: block;
        position: relative;

        &.first-input__block {
            &::before {
                content: "";
                position: absolute;
                top: -15px;
                left: 50px;
                display: block;
                width: 0;
                height: 0;
                background: transparent;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid rgba(15, 19, 42, 0.1);
                /* 수정 */
                transition: 0.2s linear;
            }
        }

        &.signup-input__block {
            &::before {
                content: "";
                position: absolute;
                top: -15px;
                left: 150px;
                display: block;
                width: 0;
                height: 0;
                background: transparent;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid rgba(15, 19, 42, 0.1);
                /* 수정 */
                transition: 0.2s linear;
            }
        }

        input {
            display: block;
            width: 90%;
            max-width: 680px;
            height: 50px;
            margin: 0 auto;
            border-radius: 8px;
            border: none;
            background: rgba(15, 19, 42, 0.1);
            /* 수정 */
            color: rgba(15, 19, 42, 0.3);
            /* 수정 */
            padding: 0 0 0 15px;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;

            &:focus,
            &:active {
                outline: none;
                border: none;
                color: rgba(15, 19, 42, 1);
                /* 수정 */
            }

            &.repeat__password {
                transition: 0.2s linear;
            }
        }
    }

    .signin__btn {
        background: #e91e63;
        color: white;
        display: block;
        width: 92.5%;
        max-width: 680px;
        height: 50px;
        border-radius: 8px;
        margin: 0 auto;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        box-shadow: 0 15px 30px rgba(233, 30, 99, 0.36);
        /* 수정 */
        transition: 0.2s linear;

        &:hover {
            box-shadow: 0 0 0 rgba(233, 30, 99, 0);
            /* 수정 */
        }
    }
}

.separator {
    display: block;
    margin: 30px auto 10px;
    text-align: center;
    height: 40px;
    position: relative;
    background: transparent;
    color: rgba(15, 19, 42, 0.4);
    /* 수정 */
    font-size: 13px;
    width: 90%;
    max-width: 680px;

    &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        background: rgba(15, 19, 42, 0.2);
        /* 수정 */
        height: 1px;
        width: 45%;
    }

    &::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 0;
        background: rgba(15, 19, 42, 0.2);
        /* 수정 */
        height: 1px;
        width: 45%;
    }
}

.google__btn,
.github__btn {
    display: block;
    width: 90%;
    max-width: 680px;
    margin: 20px auto;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;
    border: none;
    line-height: 40px;

    &.google__btn {
        background: #5b90f0;
        color: white;
        box-shadow: 0 15px 30px rgba(#5b90f0, .36);
        transition: .2s linear;

        .fa {
            font-size: 20px;
            padding: 0 5px 0 0;
        }

        &:hover {
            box-shadow: 0 0 0 rgba(#5b90f0, .0);
        }
    }

    &.github__btn {
        background: #25282d;
        color: white;
        box-shadow: 0 15px 30px rgba(#25282d, .36);
        transition: .2s linear;

        .fa {
            font-size: 20px;
            padding: 0 5px 0 0;
        }

        &:hover {
            box-shadow: 0 0 0 rgba(#25282d, .0);
        }
    }
}
</style>