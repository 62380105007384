<template>
    <div class="question">
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1 v-if="$store.state.language === 'ko'">문의사항</h1>
                        <h2 v-if="$store.state.language === 'ko'">제품 및 기타 문의 게시판입니다.</h2>

                        <h1 v-if="$store.state.language === 'en'">Questions</h1>
                        <h2 v-if="$store.state.language === 'en'">This is a bulletin board for products and other
                            inquiries.</h2>
                    </div>
                </div>

            </div>
        </section>
        <section id="faq" class="faq section-bg-noti">
            <div class="container" data-aos="fade-up">

                <div class="section-title-noti">

                    <div class="dropdown" style="padding: 0 100px;">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            @click="toggleDropdown" aria-haspopup="true" aria-expanded="false">
                            {{ selectedCategory ||  '카테고리' }}
                        </button>
                        <div class="dropdown-menu" :class="{ 'show': isDropdownOpen }"
                            aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" @click="filterByCategory('')">{{ '전체' }}</a>
                            <a class="dropdown-item" href="#" @click="filterByCategory('배송문의')">{{ '배송문의' }}</a>
                            <a class="dropdown-item" href="#" @click="filterByCategory('상품문의')">{{ '상품문의' }}</a>
                            <a class="dropdown-item" href="#" @click="filterByCategory('교환/취소/반품')">{{ '교환/취소/반품'
                                }}</a>
                            <a class="dropdown-item" href="#" @click="filterByCategory('기타')">{{ '기타' }}</a>
                        </div>

                        <router-link to="/qnaWrite" class="btn btn-secondary" style="color: white;">문의하기</router-link>
                        
                    </div>
                </div>

                <div class="notice-table">
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                                <!-- Table with hoverable rows -->
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 110px;" v-if="$store.state.language === 'ko'">
                                                번호</th>
                                            <th scope="col" style="width: 150px;" v-if="$store.state.language === 'ko'">
                                                상품정보</th>
                                            <th scope="col" style="width: 200px;" v-if="$store.state.language === 'ko'">
                                                제목</th>
                                            <th scope="col" style="width: 150px;" v-if="$store.state.language === 'ko'">
                                                카테고리</th>
                                            <th scope="col" style="width: 110px;" v-if="$store.state.language === 'ko'">
                                                작성자</th>
                                            <th scope="col" style="width: 100px;" v-if="$store.state.language === 'ko'">
                                                날짜</th>

                                            <th scope="col" style="width: 110px;" v-if="$store.state.language === 'en'">
                                                Num</th>
                                            <th scope="col" style="width: 150px;" v-if="$store.state.language === 'en'">
                                                Product Information</th>
                                            <th scope="col" style="width: 200px;" v-if="$store.state.language === 'en'">
                                                Title</th>
                                            <th scope="col" style="width: 150px;" v-if="$store.state.language === 'en'">
                                                Category</th>
                                            <th scope="col" style="width: 110px;" v-if="$store.state.language === 'en'">
                                                Writer</th>
                                            <th scope="col" style="width: 100px;" v-if="$store.state.language === 'en'">
                                                Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="question in paginatedQuestion.slice().reverse()"
                                            :key="question.qnaNum">
                                            <td>{{ question.qnaNum }}</td>
                                            <td>{{ question.qnaProduct }}</td>
                                            <td><a href="/question/questionDetail/${qnaNum}"
                                                    @click.prevent="redirectToDetail(question.qnaNum)">{{
                                                        question.qnaTitle[$store.state.language] }}</a>
                                            </td>
                                            <td>{{ question.category[$store.state.language] }}</td>
                                            <td>{{ question.qnaWriter[$store.state.language] }}</td>
                                            <td>{{ question.qnaDate }}</td>
                                        </tr> -->
                                        <tr v-for="(question, index) in paginatedQuestion.slice().reverse()"
                                            :key="question.qnaNum">
                                            <td>{{ paginatedQuestion.length - index }}</td>
                                            <td>{{ question.qnaProduct }}</td>
                                            <td><a href="#" @click.prevent="redirectToDetail(question.qnaNum)">{{
                                                    question.qnaTitle }}</a></td>
                                            <td>{{ question.category }}</td>
                                            <td>{{ extractUsername(question.qnaWriter) }}</td>
                                            <td>{{ question.qnaDate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- End Table with hoverable rows -->

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section>
            <nav aria-label="Page navigation example" style="text-align: center;" v-if="totalPages > 1">
                <ul class="pagination" style="justify-content: center;">
                    <li @click="prevPage" class="page-item" :class="{ 'disabled': currentPage === 1 }">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li v-for="page in visiblePages" :key="page" class="page-item"
                        :class="{ 'active': currentPage === page }">
                        <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
                    </li>
                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                    <li class="page-item" @click="nextPage" :class="{ 'disabled': currentPage === totalPages }">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav><!-- End Pagination with icons -->
        </section>
    </div>
</template>
<script>
// import { question } from '@/scripts/question.js';
export default {
    name: 'question',
    data() {
        return {
            isDropdownOpen: false,
            selectedCategory: '',
            // question: question,
            questions: [],
            itemsPerPage: 5, // 한 페이지에 표시할 항목 수
            currentPage: 1 // 현재 페이지 번호
        }
    },
    computed: {
        filteredQuestion() {
            if (!this.selectedCategory) {
                return this.questions; // 선택된 카테고리가 없으면 모든 질문을 반환
            }
            return this.questions.filter(item => {
                // db에 저장된 카테고리와 사용자가 선택한 카테고리를 비교
                return item.category === this.selectedCategory;
            });
        },
        // 현재 페이지에 해당하는 데이터만 반환하는 computed property
        paginatedQuestion() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            const reversedQuestion = this.filteredQuestion.slice().reverse(); // 역순으로 데이터를 가져옴
            const paginatedReversedQuestion = reversedQuestion.slice(startIndex, endIndex);
            return paginatedReversedQuestion.reverse(); // 페이지에 표시될 데이터를 다시 역순으로 되돌림
        },
        // 전체 페이지 수를 계산하는 computed property
        totalPages() {
            return Math.ceil(this.filteredQuestion.length / this.itemsPerPage);
        },
        // 페이지네이션에 표시될 페이지 번호들을 계산하는 computed property
        visiblePages() {
            const maxVisiblePages = 5; // 표시할 최대 페이지 수
            let startPage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
            const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);

            // startPage가 1보다 작으면 endPage를 조정하여 페이지 수가 maxVisiblePages만큼 유지되도록 함
            const offset = Math.max(0, 1 - startPage);
            startPage += offset;

            // startPage부터 endPage까지의 배열 생성
            return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
        }
    },
    methods: {
        extractUsername(email) {
            return email.split('@')[0];
        },
        // 이전 페이지로 이동하는 메소드
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        // 다음 페이지로 이동하는 메소드
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        // 특정 페이지로 이동하는 메소드
        goToPage(page) {
            this.currentPage = page;

        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        filterByCategory(category) {
            this.selectedCategory = category;
            this.isDropdownOpen = false; // 드롭다운을 닫습니다.
            this.currentPage = 1;
        },
        redirectToDetail(qnaNum) {
            const detailUrl = `/question/questionDetail/${qnaNum}`;
            console.log(qnaNum);
            window.location.href = detailUrl;
        },
        loadQuestions() {
            // IndexedDB에서 데이터를 불러와서 this.questions에 저장하는 로직
            const request = indexedDB.open('myDatabase', 1); // 수정: 데이터베이스 이름을 변경합니다.
            request.onsuccess = event => {
                const db = event.target.result;
                const transaction = db.transaction('questions', 'readonly');
                const store = transaction.objectStore('questions');
                store.getAll().onsuccess = event => {
                    this.questions = event.target.result;
                };
            };
            request.onerror = event => {
                console.error('Database error: ' + event.target.errorCode);
            };

        }
    },
    created() {
        this.loadQuestions();
    }
}
</script>
<style scoped>
body {
    color: #444444;
}

a {
    color: #5777ba;
    text-decoration: none;
}

a:hover {
    color: #82a0e2;
    text-decoration: none;
}


.dropdown {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg-noti {
    background-color: #fff;
    padding: 60px 0;
}

.section-title-noti {
    /* text-align: center; */
    padding-bottom: 30px;
}

.section-title-noti h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5777ba;
}

.section-title-noti p {
    margin-bottom: 0;
}



/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
    padding: 0 100px;
}

.faq .notice-table ul {
    padding: 0;
    list-style: none;
}

.faq .notice-table li+li {
    margin-top: 15px;
}

.faq .notice-table li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .notice-table a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
}


.faq .notice-table p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
    .faq .accordion-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 30vh;
    /* background: url("@/assets/images/intro-3.png") top center; */
    background-color: #f2f5fa;
    background-size: cover;
    position: relative;
}

#hero:before {
    content: "";
    /* background: rgba(0, 0, 0, 0.1); */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 10px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #5777ba;
}

#hero h1 span {
    color: #5777ba;
}

#hero h2 {
    color: rgba(86, 108, 110, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #5777ba;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #5777ba;
}

#hero .icon-box:hover {
    border-color: #5777ba;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}
</style>