<template>
    <div class="dihApp">
        <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
      <div>
        <h1 v-if="$store.state.language === 'ko'">닥터인홈 APP 다운로드 받기</h1>
        <h1 v-if="$store.state.language === 'en'">Download Doctor in Home APP</h1>
        <h2 v-if="$store.state.language === 'ko'">가족들과 함께하는 우리 아이 맞춤 기록의 시작</h2>
        <h2 v-if="$store.state.language === 'en'">The beginning of a personalized record for your child with the family</h2>
        <a href="#" class="download-btn"><i class="bx bxl-play-store"></i> Google Play</a>
        <a href="#" class="download-btn"><i class="bx bxl-apple"></i> App Store</a>
      </div>
    </div>
    <div class="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
      <img v-if="$store.state.language === 'ko'" src="@/assets/images/app-1.png" class="img-fluid" alt="">
      <img v-if="$store.state.language === 'en'" src="@/assets/images/app-11.png" class="img-fluid" alt="">
    </div>
  </div>
</div>

</section><!-- End Hero -->

<main id="main">

<!-- ======= App Features Section ======= -->
<section id="features" class="features">
  <div class="container">

    <div class="section-title">
      <h2 v-if="$store.state.language === 'ko'">APP 기능 소개</h2>
      <h2 v-if="$store.state.language === 'en'">APP function introduction</h2>
      <p v-if="$store.state.language === 'ko'">가족 구성원 모두가 보호자가 되어 우리 아이의 건강을 기록할 수 있어요. 발열, 복용약, 중이염 상태를 한눈에 보고 맞춤 케어를 시작해보세요.</p>
      <p v-if="$store.state.language === 'en'">All family members can become guardians and record their child's health. Check your fever, medications, and otitis media status at a glance and start personalized care.</p>
    </div>

    <div class="row no-gutters">
      <div class="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
        <div class="content d-flex flex-column justify-content-center">
          <div class="row">
            <div class="col-md-6 icon-box" data-aos="fade-up">
              <i class='bx bx-child'></i>
              <h4 v-if="$store.state.language === 'ko'">하나의 계정으로 가족구성원 모두 이용 가능</h4>
              <h4 v-if="$store.state.language === 'en'">All family members can use one account</h4>
              <p v-if="$store.state.language === 'ko'">번거로운 회원가입은 한 번만! 계정 추가를 이용하여 보호자 정보만 입력하면 모두 우리 아이 케어를 함께할 수 있습니다.</p>
              <p v-if="$store.state.language === 'en'">Sign up for membership only once! All you have to do is enter the guardian information by adding an account, and everyone can take care of your child together.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <i class='bx bx-home-heart' ></i>
              <h4 v-if="$store.state.language === 'ko'">집에서도 중이염 진단 가능</h4>
              <h4 v-if="$store.state.language === 'en'">Otitis media can be diagnosed at home</h4>
              <p v-if="$store.state.language === 'ko'">중이염 단계별 비교 사진을 제공하여 집에서도 중이염 진단이 가능합니다.</p>
              <p v-if="$store.state.language === 'en'">It is possible to diagnose otitis media at home by providing comparative photos of each stage of otitis media.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
              <i class='bx bxs-thermometer'></i>
              <h4 v-if="$store.state.language === 'ko'">체온과 복약을 함께 기록</h4>
              <h4 v-if="$store.state.language === 'en'">Record body temperature and medication together</h4>
              <p v-if="$store.state.language === 'ko'">매번 어떤 약을 처방받았는지 기억하기 위해 약봉투를 모아두셨나요? 이제 사진만 찍으면 처방받은 약을 기록해주고, 별도 복약 기록을 추가하여 언제든지 확인할 수 있습니다.</p>
              <p v-if="$store.state.language === 'en'">Have you saved a medicine bag to remember which medicine you are prescribed each time? Now, just by taking a photo, you can record the prescribed medication and add a separate medication record to check it at any time.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
              <i class='bx bxs-capsule' ></i>
              <h4 v-if="$store.state.language === 'ko'">복약 관리</h4>
              <h4 v-if="$store.state.language === 'en'">Medication management</h4>
              <p v-if="$store.state.language === 'ko'">항생제 성분은 구분하기 쉽게 별도의 표기가 되어있습니다. 몸무게와 키 정보를 입력해주셨다면 권장 표준용량이 안내되어 약국에서 빠른 복용이 가능합니다.</p>
              <p v-if="$store.state.language === 'en'">Antibiotic ingredients are labeled separately for easy identification. If you enter your weight and height information, the recommended standard dosage will be provided so you can take it quickly at a pharmacy.</p>
            </div>
            <!-- <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
              <i class="bx bx-atom"></i>
              <h4>Molestiae dolor</h4>
              <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-id-card"></i>
              <h4>Explicabo consectetur</h4>
              <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
        <img src="@/assets/images/app-2.svg" class="img-fluid" alt="">
      </div>
    </div>

  </div>
</section><!-- End App Features Section -->

<!-- ======= Details Section ======= -->
<section id="details" class="details">
  <div class="container">

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4">
        <img v-if="$store.state.language === 'ko'" src="@/assets/images/app-3.png" class="img-fluid" alt="">
        <img v-if="$store.state.language === 'en'" src="@/assets/images/app-13.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-4">
        <h3 v-if="$store.state.language === 'ko'">1. 체온계로 체온을 기록해주세요.</h3>
        <h3 v-if="$store.state.language === 'en'">1. Record your body temperature with a thermometer.</h3>
        <p class="fst-italic" v-if="$store.state.language === 'ko'">
          정확한 체온을 확인할 수 있도록 여러번 측정 후 작성해주세요.
        </p>
        <p class="fst-italic" v-if="$store.state.language === 'en'">
          Please measure your body temperature several times and then fill it out so that we can check your temperature accurately.
        </p>
        <ul>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 체온계에 표시된 온도를 작성합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> Write down the temperature indicated on the thermometer.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 체온을 측정한 오늘 날짜와 현재 시간을 선택합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> Select today's date and current time when your temperature was measured.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 확인한 아이의 증상을 보호자님이 직접 기록합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> The guardian directly records the child's confirmed symptoms.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 고열일 경우 '촬영 및 중이염 단계 선택' 버튼을 눌러 촬영하고, 정상일 경우 저장하기 클릭 시 기록이 완료됩니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> If you have a high fever, click the 'Select Imaging and Otitis Media Stage' button to take pictures. If it's normal, click Save to complete the recording.</li>
        </ul>
        <p v-if="$store.state.language === 'ko'">
          계정을 공유하는 모든 보호자님이 기록한 증상은 각각 어플에서 한번에 확인 가능합니다.
        </p>
        <p v-if="$store.state.language === 'en'">
          Symptoms recorded by all guardians sharing an account can be checked at once in the app.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4 order-1 order-md-2">
        <img v-if="$store.state.language === 'ko'" src="@/assets/images/app-4.png" class="img-fluid" alt="">
        <img v-if="$store.state.language === 'en'" src="@/assets/images/app-14.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1">
        <h3 v-if="$store.state.language === 'ko'">2. 귀 내시경 촬영 후 중이염 단계를 선택해주세요.</h3>
        <h3 v-if="$store.state.language === 'en'">2. Please select the stage of otitis media after endoscopic imaging.</h3>
        <p class="fst-italic" v-if="$store.state.language === 'ko'">
          촬영 없이 중이염 단계만 선택 가능하지만 촬영하여 비교하는 것이 더 정확합니다.
        </p>
        <p class="fst-italic" v-if="$store.state.language === 'en'">
          Although it is possible to select only the otitis media stage without taking a picture, it is more accurate to take a picture and compare.
        </p>
        <ul>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 귀 촬영 버튼 클릭 시 기계와 연동됩니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> When you click the ear capture button, it is linked to the machine.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 재촬영하기 버튼으로 다시 찍을 수 있으며 기존 사진은 삭제됩니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> You can take a new photo with the Retake button and the existing photo will be deleted.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 중이염 단계별 증상을 확인하고 촬영한 사진과 비교하여 선택합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> Check the symptoms of otitis media at each stage and compare them with the photos taken to make your selection.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 저장하기 클릭 시 다른 쪽도 촬영할 수 있는 선택창으로 넘어갑니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> When you click Save, you will be taken to a selection window where you can take pictures of the other side as well.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 모두 저장할 경우 체온 및 증상과 함께 선택한 중이염 단계도 기록됩니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> If you save them all, the selected otitis media stage will be recorded along with your temperature and symptoms.</li>
        </ul>
        <p v-if="$store.state.language === 'ko'">
          선택한 중이염 단계에 따라 병원 방문 여부를 안내해드립니다.
        </p>
        <p v-if="$store.state.language === 'en'">
          Depending on the stage of otitis media you select, you will be advised whether or not to visit the hospital.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4">
        <img v-if="$store.state.language === 'ko'" src="@/assets/images/app-5.png" class="img-fluid" alt="">
        <img v-if="$store.state.language === 'en'" src="@/assets/images/app-15.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5">
        <h3 v-if="$store.state.language === 'ko'">3. 복약을 기록해주세요.</h3>
        <h3 v-if="$store.state.language === 'en'">3. Please record your medication.</h3>
        <p v-if="$store.state.language === 'ko'">복약 기록으로 우리 아이에게 잘 맞는 약을 찾을 수 있습니다.</p>
        <p v-if="$store.state.language === 'en'">With the medication history, you can find the medication that is best for your child.</p>
        <ul>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 복약 기록하기 버튼을 누르고 기록하는 화면으로 넘어갑니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> Click the Medication Record button and proceed to the recording screen.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 처방봉투 촬영 시 자동으로 약품명이 기록됩니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> When taking a photo of a prescription envelope, the drug name is automatically recorded.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 별도의 약을 복용했을 경우, 직접 입력을 클릭하고 약품명을 검색하여 복용한 약을 선택합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> If you took a separate medication, click Enter directly, search the drug name, and select the medication you took.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 저장하기를 누르면 입력한 복약 시간과 방문 병원, 처방약 확인이 가능합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> If you click Save, you can check the entered medication time, hospital visited, and prescribed medication.</li>
        </ul>
        <p v-if="$store.state.language === 'ko'">
          항생제 성분이 들어있는 약품명에는 별도의 표기가 되어 있어 손쉽게 구분할 수 있습니다.
        </p>
        <p v-if="$store.state.language === 'en'">
          Drug names containing antibiotic ingredients are marked separately so they can be easily identified.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4 order-1 order-md-2" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <img v-if="$store.state.language === 'ko'" src="@/assets/images/app-6.png" class="img-fluid" alt="">
        <img v-if="$store.state.language === 'en'" src="@/assets/images/app-16.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1">
        <h3 v-if="$store.state.language === 'ko'">4. 닥터인홈과 함께 우리 아이를 케어하세요.</h3>
        <h3 v-if="$store.state.language === 'en'">4. Take care of your child with Doctor in Home.</h3>
        <p class="fst-italic" v-if="$store.state.language === 'ko'">
          어플 알람으로 지속적인 우리 아이 상태 확인이 가능합니다.
        </p>
        <p class="fst-italic" v-if="$store.state.language === 'en'">
          You can continuously check your child's status with the app alarm.
        </p>
        <ul>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 체온 기록으로 고열에서 정상 체온이 될 때까지 걸린 시간을 확인합니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> Temperature recording determines how long it takes for a person to go from high fever to normal body temperature.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 아이의 상태를 놓치지 않도록 체온을 잴 수 있게 알람이 울립니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> An alarm sounds so you can measure your child's temperature so you don't miss out on your child's condition.</li>
          <li v-if="$store.state.language === 'ko'"><i class="bi bi-check"></i> 스크롤만으로 우리 아이의 건강을 확인할 수 있습니다.</li>
          <li v-if="$store.state.language === 'en'"><i class="bi bi-check"></i> You can check your child's health with just a scroll.</li>
        </ul>
        <p v-if="$store.state.language === 'ko'">
          닥터인홈, 지금 바로 시작하세요.
        </p>
        <p v-if="$store.state.language === 'en'">
          Doctor in Home, start right now.
        </p>
      </div>
    </div>

  </div>
</section><!-- End Details Section -->

<!-- ======= Gallery Section ======= -->
<!-- <section id="gallery" class="gallery">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Gallery</h2>
      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
    </div>

  </div>

  <div class="container-fluid" data-aos="fade-up">
    <div class="gallery-slider swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-1.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-1.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-2.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-2.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-3.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-3.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-4.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-4.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-5.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-5.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-6.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-6.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-7.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-7.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-8.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-8.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-9.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-9.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-10.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-10.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-11.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-11.png" class="img-fluid" alt=""></a></div>
        <div class="swiper-slide"><a href="assets/img/gallery/gallery-12.png" class="gallery-lightbox" data-gall="gallery-carousel"><img src="assets/img/gallery/gallery-12.png" class="img-fluid" alt=""></a></div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

  </div>
</section> -->
<!-- End Gallery Section -->

<!-- ======= Testimonials Section ======= -->
<!-- <section id="testimonials" class="testimonials section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2 v-if="$store.state.language === 'ko'">사용자 후기</h2>
      <h2 v-if="$store.state.language === 'en'">User reviews</h2>
      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
    </div>

    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Saul Goodman</h3>
            <h4>Ceo &amp; Founder</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
            <h3>Sara Wilsson</h3>
            <h4>Designer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
            <h3>Jena Karlis</h3>
            <h4>Store Owner</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>John Larson</h3>
            <h4>Entrepreneur</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>

      </div>
      <div class="swiper-pagination"></div>
    </div>

  </div>
</section> -->
<!-- End Testimonials Section -->

<!-- ======= Pricing Section ======= -->
<!-- <section id="pricing" class="pricing">
  <div class="container">

    <div class="section-title">
      <h2>Pricing</h2>
      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
    </div>

    <div class="row no-gutters">

      <div class="col-lg-4 box" data-aos="fade-right">
        <h3>Free</h3>
        <h4>$0<span>per month</span></h4>
        <ul>
          <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
          <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
          <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
          <li class="na"><i class="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
          <li class="na"><i class="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
        </ul>
        <a href="#" class="get-started-btn">Get Started</a>
      </div>

      <div class="col-lg-4 box featured" data-aos="fade-up">
        <h3>Business</h3>
        <h4>$29<span>per month</span></h4>
        <ul>
          <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
          <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
          <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
          <li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
          <li><i class="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
        </ul>
        <a href="#" class="get-started-btn">Get Started</a>
      </div>

      <div class="col-lg-4 box" data-aos="fade-left">
        <h3>Developer</h3>
        <h4>$49<span>per month</span></h4>
        <ul>
          <li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
          <li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
          <li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
          <li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
          <li><i class="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
        </ul>
        <a href="#" class="get-started-btn">Get Started</a>
      </div>

    </div>

  </div>
</section> -->
<!-- End Pricing Section -->

<!-- ======= Frequently Asked Questions Section ======= -->


<!-- ======= Contact Section ======= -->
<!-- <section id="contact" class="contact">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Contact</h2>
      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
    </div> -->

    <!-- <div class="row">

      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6 info">
            <i class="bx bx-map"></i>
            <h4>Address</h4>
            <p>A108 Adam Street,<br>New York, NY 535022</p>
          </div>
          <div class="col-lg-6 info">
            <i class="bx bx-phone"></i>
            <h4>Call Us</h4>
            <p>+1 5589 55488 55<br>+1 5589 22548 64</p>
          </div>
          <div class="col-lg-6 info">
            <i class="bx bx-envelope"></i>
            <h4>Email Us</h4>
            <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="f5969a9b81949681b5908d9498859990db969a98">[email&#160;protected]</a><br><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="bbd2d5ddd4fbdec3dad6cbd7de95d8d4d6">[email&#160;protected]</a></p>
          </div>
          <div class="col-lg-6 info">
            <i class="bx bx-time-five"></i>
            <h4>Working Hours</h4>
            <p>Mon - Fri: 9AM to 5PM<br>Sunday: 9AM to 1PM</p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <form action="forms/contact.php" method="post" role="form" class="php-email-form" data-aos="fade-up">
          <div class="form-group">
            <input placeholder="Your Name" type="text" name="name" class="form-control" id="name" required>
          </div>
          <div class="form-group mt-3">
            <input placeholder="Your Email" type="email" class="form-control" name="email" id="email" required>
          </div>
          <div class="form-group mt-3">
            <input placeholder="Subject" type="text" class="form-control" name="subject" id="subject" required>
          </div>
          <div class="form-group mt-3">
            <textarea placeholder="Message" class="form-control" name="message" rows="5" required></textarea>
          </div>
          <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center"><button type="submit">Send Message</button></div>
        </form>
      </div>

    </div> -->

  <!-- </div>
</section> -->
<!-- End Contact Section -->

</main><!-- End #main -->
    </div>
</template>
<script>
import 'boxicons/css/boxicons.min.css';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import {onMounted} from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    name: 'DIHApp',
    setup() {
    onMounted(() => {
      AOS.init({
        // Optional settings
        duration: 1000,
      });
    });
  },
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    },
    mounted(){
      this.swiper = new Swiper('.testimonials-slider', {
        pagination: {
          el: '.swiper-pagination',
        },
      });
    },
}
</script>
<style scoped>

@import url('@/assets/css/dihApp.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');
/**
* Template Name: Appland
* Template URL: https://bootstrapmade.com/free-bootstrap-app-landing-page-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #585a61;
}

a {
  color: #5777ba;
  text-decoration: none;
}

a:hover {
  color: #7b94c9;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #5777ba;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #748ec6;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #47536e;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #5777ba;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #5777ba;
  color: #fff;
  padding: 12px 25px;
  margin-left: 30px;
  color: #fff;
  line-height: 1;
  border-radius: 50px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  background: #748ec6;
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #47536e;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(51, 60, 79, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #47536e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #5777ba;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 140px 0 100px 0;
}

#hero::before {
  content: "";
  position: absolute;
  right: -100%;
  top: 20%;
  width: 250%;
  height: 200%;
  z-index: -1;
  background-color: #e8ecf5;
  transform: skewY(135deg);
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #5777ba;
}

#hero h2 {
  color: #515f7d;
  margin-bottom: 50px;
  font-size: 20px;
}

#hero .download-btn {
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 8px 24px 10px 46px;
  border-radius: 3px;
  transition: 0.5s;
  color: #fff;
  background: #47536e;
  position: relative;
}

#hero .download-btn:hover {
  background: #5777ba;
}

#hero .download-btn i {
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 8.5px;
}

#hero .download-btn+.download-btn {
  margin-left: 20px;
}

@media (max-width: 991px) {
  #hero {
    text-align: center;
  }

  #hero .download-btn+.download-btn {
    margin: 0 10px;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f2f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  margin-top: 75px;
  padding: 15px 0;
  background-color: #f6f8fb;
  min-height: 40px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 60px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# App Features
--------------------------------------------------------------*/
.features .content {
  padding: 30px 0;
}

.features .content .icon-box {
  margin-top: 25px;
}

.features .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .content .icon-box i {
  font-size: 48px;
  float: left;
  color: #5777ba;
}

.features .content .icon-box p {
  font-size: 15px;
  color: #979aa1;
  margin-left: 60px;
}

@media (max-width: 991px) {
  .features .image {
    text-align: center;
  }

  .features .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .features .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/
.details .content+.content {
  margin-top: 100px;
}

.details .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #47536e;
}

.details .content ul {
  list-style: none;
  padding: 0;
}

.details .content ul li {
  padding-bottom: 10px;
  font-size: 17px;
}

.details .content ul i {
  font-size: 24px;
  padding-right: 2px;
  color: #5777ba;
  line-height: 0;
}

.details .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-slide {
  transition: 0.3s;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    border: 6px solid #5777ba;
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
    margin-top: 10px;
    border-radius: 25px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 10px 30px 50px;
  min-height: 200px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e8ecf5;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5777ba;
  font-weight: 400;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5777ba;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .get-started-btn {
  background: #47536e;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
}

.pricing .get-started-btn:hover {
  background: #5777ba;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .get-started-btn {
  background: #5777ba;
}

.pricing .featured .get-started-btn:hover {
  background: #748ec6;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .accordion-list {
  padding: 0 100px;
}

.faq .accordion-list ul {
  padding: 0;
  list-style: none;
}

.faq .accordion-list li+li {
  margin-top: 15px;
}

.faq .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .accordion-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #b1c0df;
}

.faq .accordion-list .icon-show,
.faq .accordion-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .accordion-list .icon-show {
  display: none;
}

.faq .accordion-list a.collapsed {
  color: #343a40;
}

.faq .accordion-list a.collapsed:hover {
  color: #5777ba;
}

.faq .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  padding: 20px 40px;
  background: #f1f3f6;
  color: #47536e;
  text-align: center;
  border: 1px solid #fff;
}

.contact .info i {
  font-size: 48px;
  color: #9fb2d8;
  margin-bottom: 15px;
}

.contact .info h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact .info p {
  font-size: 15px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form label {
  margin-bottom: 5px;
  color: #8a8c95;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #5777ba;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #fff;
  border: 2px solid #5777ba;
  padding: 10px 24px;
  color: #5777ba;
  transition: 0.4s;
  border-radius: 50px;
  margin-top: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5777ba;
  color: #fff;
}

@media (max-width: 1024px) {
  .contact .php-email-form {
    padding: 30px 15px 15px 15px;
  }
}

@media (max-width: 768px) {
  .contact .php-email-form {
    padding: 15px 0 0 0;
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



</style>