export const notice = [
    {
        noticeNum: 1, 
        noticeTitle: {
            ko: '닥터인홈 앱 출시 안내', 
            en: 'DoctorInHome app release guide'
        }, 
        noticeWriter: {
            ko: '닥터인홈', 
            en: 'DoctorInHome'
        }, 
        noticeRdcnt: 0, 
        noticeDate: '2024-04-18', 
        noticeContent: {
            ko: `
                <img src="${require('@/assets/images/item-3.png')}" alt="닥터인홈 앱 이미지" class="centered-image">
            `,
            en: `
                <img src="${require('@/assets/images/item-3eng.png')}" alt="DoctorInHome app image" class="centered-image">
            `
        }
    }
]