<template>
    <div class="health">

        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1>Without Antibiotics<span>.</span></h1>
                        <!-- <h2>Take care of your children effectively.</h2> -->
                    </div>
                </div>

            </div>
        </section><!-- End Hero -->

        <main id="main">

            <!-- ======= About Section ======= -->
            <div v-if="currentSection === 'OtitisMedia'">
                <section id="services" class="services">
                    

                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'ko'">
                                        <img src="@/assets/images/health-111.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'en'">
                                        <img src="@/assets/images/health-1.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1 v-if="$store.state.language === 'ko'">소아 중이염의 예방과 관리</h1>
                                                        <h1 v-if="$store.state.language === 'en'">Prevention and Management of Pediatric Otitis Media</h1>
                                                        <p v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-112.png" alt="" class="responsive-image">
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        
                                                        <h2 v-if="$store.state.language === 'ko'">예방 방법</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Prevention Strategies</h2>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            세균 감염 등으로 고막 안쪽에 물이 고이는 질환인 중이염은 3세 이하 소아의 약 60%가 한 번 이상 걸린다고 보고됩니다. 그 이유는 소아의 이관 구조가 성인과 달리 짧고 굵으며, 수평으로 위치해 있어 중이염이 발생하기 쉽기 때문입니다. 특히 한 번 중이염을 않으면 그 중 절반 이상이 세 번 이상 재발하는 것으로 알려졌습니다. 또 제대로 치료하지 못하면 영유아 청력 저하의 주요 원인으로 작용해서 올바른 관리가 필요합니다. 치료만큼 중요한 예방과 관리하는 방법에 대해 알아보겠습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Otitis media, a condition where fluid accumulates inside the eardrum due to bacterial infection, affects about 60% of children under the age of three at least once. This is because the Eustachian tubes in children are shorter, wider, and more horizontally positioned compared to adults, making them more prone to otitis media. Notably, more than half of the children who develop otitis media experience recurrent episodes, often more than three times. If not properly treated, otitis media can lead to hearing loss in infants and young children, highlighting the importance of proper management. Let's explore effective prevention and management strategies.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            소아 중이염은 세균 감염으로 많이 발생하기 때문에 예방을 위해 페렴구균 또는 인플루엔자 등의 백신 접종이 도움이 됩니다. 이 같은 백신은 중이염 발생률을 약 43% 감소시키는 것으로 보고됩니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Since bacterial infections are a common cause of pediatric otitis media, vaccinations against pneumococcus or influenza can help in prevention. These vaccines have been reported to reduce the incidence of otitis media by about 43%.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            영유아의 경우 감기 후유증으로 중이염이 발생하는 경우가 많아 감기 역시 주의해야 합니다. 외출 후 귀가 시 손을 씻으면 중이염을 일으키는 상기도 감염을 예방할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            In infants and young children, otitis media often develops as a complication of a cold, so it is important to prevent colds as well. Washing hands after coming home can help prevent upper respiratory infections that can lead to otitis media.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            부비동염이나 비염이 있다면 미리 치료해주는 것이 좋습니다. 코에 염증이 있으면 중이염에 걸리기 쉬워 코건강을 평소 챙겨주어야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Treating sinusitis or rhinitis in advance is also advisable. Inflammation in the nose can make children more susceptible to otitis media, so maintaining nasal health is crucial.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            담배 연기 역시 중이염을 일으키는 중요한 원인 중 하나입니다. 아이들이 간접흡연에 노출되지 않도록 주변 환경 관리에 신경을 써주어야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Cigarette smoke is another significant cause of otitis media. It's important to ensure that children are not exposed to secondhand smoke by managing their environment carefully.
                                                        </p>

                                                        <h2 v-if="$store.state.language === 'ko'">관리 방법</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Management Strategies</h2>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            아이가 중이염에 걸리면 엄마들이 가장 걱정하는 키워드는 크게 '항생제', '수술', '청력저하'입니다. 중이염을 항생제로 치료해야 한다는 편견에 비해 막상 항생제를 써야 하는 경우는 '귀 통증이 심각하거나, 38.5도 이상의 고열이 있거나, 생후 6개월 이하인 아이' 이렇게 3가지를 우선으로 하고 있습니다. 이는 급성 중이염 증상이며, 급성 중이염은 세균성 감염이므로 항생제를 써서 단기간에 치료해야 합니다. 귀 통증과 고열 없이 '귀에 물이 찼네요' 라고 하는 삼출성 중이염은 바이러스성 감염으로 항생제를 쓰지 않는 것이 원칙입니다. 또한 6~70%의 중이염은 이와 같은 삼출성 중이염입니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            When a child develops otitis media, the main concerns for parents are typically 'antibiotics', 'surgery', and 'hearing loss'. Contrary to the common belief that otitis media must be treated with antibiotics, the actual indications for antibiotics are severe ear pain, a high fever over 38.5 degrees Celsius, or an age of under six months. These are symptoms of acute otitis media, which is caused by bacterial infections and should be treated promptly with antibiotics. Otitis media with effusion, characterized by fluid in the ear without severe pain or fever, is usually viral and does not require antibiotics. Approximately 60-70% of otitis media cases fall into this category.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            삼출성 중이염은 세균으로 인한 급성 중이염을 앓은 후 고름이 다 빠져나가지 못해 발생하거나, 또는 세균 감염 없이 이관 기능이 나빠져 진공 상태가 되면서 삼출액이 분비되며 발생합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Otitis media with effusion can occur after an episode of acute otitis media when pus doesn't completely drain out, or it can develop without bacterial infection due to poor Eustachian tube function leading to a vacuum state and fluid secretion.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            대부분 2개월 내에 자연 치료가 됩니다. 하지만 고막 안쪽에 삼출물이 차게 되면 청력 저하가 필연적으로 발생하게 되는데 언어 습득 시기에 있는 소아의 경우 청력감소는 단기간이라도 뇌의 언어중추 발달에 지장을 초래할 수 있기 때문에 적극적으로 관리해야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Most cases resolve naturally within two months. However, fluid accumulation inside the eardrum can cause hearing loss, which is critical during the language acquisition period in young children. Even short-term hearing loss can interfere with the development of the brain's language center, necessitating active management.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            닥터인홈은 꾸준하고 간편한 관리에 도움을 드립니다. 열이 높지 않아도 촬영할 수 있는 내시경 사진으로 아이의 고막을 관찰하고 중이염 진행 단계를 확인할 수 있습니다. 또 소염진통제를 복용하는 기간에도 언제든지 촬영하여 호전 속도를 확인할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            DoctorInHome offers consistent and convenient management solutions. By using endoscopic images to monitor the child's eardrum, you can observe the progression of otitis media, even when there is no high fever. You can also monitor the recovery progress while the child is taking anti-inflammatory pain relievers.
                                                        </p>

                                                        <h3 v-if="$store.state.language === 'ko'">항생제의 오남용을 줄일 수 있는 관리, 닥터인홈으로 시작해보세요.</h3>
                                                        <h3 v-if="$store.state.language === 'en'">Start reducing antibiotic misuse with effective management from DoctorInHome.</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section>

            </div>

            <div v-if="currentSection === 'Antibiotics'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'ko'">
                                        <img src="@/assets/images/health-113.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'en'">
                                        <img src="@/assets/images/health-3.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1 v-if="$store.state.language === 'ko'">항생제 내성에 주의하세요</h1>
                                                        <h1 v-if="$store.state.language === 'en'">Beware of Antibiotic Resistance</h1>

                                                        <p v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-112.png" alt="" class="responsive-image">
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>

                                                        <h2 v-if="$store.state.language === 'ko'">항생제와 사용법에 대해 알아보아요</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Understanding Antibiotics and Their Use</h2>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            항생제는 우리 몸에 세균이 침입하여 감염을 일으키면 이를 치료하는데 사용합니다. 하지만 항생제를 사용하면 모든 질환이 빨리 낫는다는 잘못된 지식으로 인해 오남용되는 사례가 종종 있습니다. 항생제 과다 사용 및 오남용은 항생제를 사용해도 생존해서 증식하는 항생제 내성균을 만들어내는데, 이것이 바로 모두가 두려워하는 항생제 내성입니다. 항생제를 투여해도 효과가 적어 패혈증, 폐렴 등 생명을 위협하는 감염 질환 시 사망에 이르기도 합니다. 항생제 오남용과 섣부른 단약은 항생제 내성균 전파에 큰 영향을 줍니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Antibiotics are used to treat infections caused by bacteria that invade our bodies. However, there are often cases of misuse due to the misconception that antibiotics can quickly cure all illnesses. Overuse and misuse of antibiotics lead to the development of antibiotic-resistant bacteria that can survive and proliferate even in the presence of antibiotics. This resistance is a serious concern because it can render antibiotics ineffective, resulting in potentially fatal infections such as sepsis and pneumonia. Both improper use and premature discontinuation of antibiotics significantly contribute to the spread of antibiotic-resistant bacteria.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            항생제 내성을 막기 위해 잘 복용하는 방법을 알아보겠습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Let's learn how to properly use antibiotics to prevent resistance.
                                                        </p>

                                                        <h2 v-if="$store.state.language === 'ko'">항생제를 잘 복용하는 세가지 방법</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Three Proper Ways to Take Antibiotics</h2>

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-114.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-4.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            항생제는 감기나 인플루엔자처럼 '바이러스' 질환에는 효과적이지 않습니다. 항생제는 세균에만 반응하는 약물이기 때문에 발병 원인이 바이러스인 경우 항생제 치료는 효과가 없습니다. 감기를 치료하던 중 폐렴, 부비동염, 편도염 등의 세균 감염이 생겼을 때는 항생제 복용이 필요할 수 있습니다. 하지만 이를 이차 세균감염을 예방하기 위한 항생제 복용도 필요하지 않습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Antibiotics are ineffective against viral illnesses such as the common cold or influenza. Since antibiotics target bacteria, they do not work against viruses. While bacterial infections such as pneumonia, sinusitis, and tonsillitis that develop during a cold may require antibiotics, taking antibiotics to prevent secondary bacterial infections is unnecessary.
                                                        </p>

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-115.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-5.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            처방받은 약은 끝까지 복용해야 합니다. 증상이 완화되었다고 중간에 단약할 경우 감염이 완전히 치료되지 않고 세균이 내성을 가질 수 있도록 유도할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            It is crucial to complete the prescribed course of antibiotics. Stopping treatment prematurely when symptoms improve can lead to incomplete eradication of the infection and promote the development of resistant bacteria.
                                                        </p>

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-116.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-6.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            일정한 시간 간격으로 복용해야 합니다. 항생제 농도를 몸 안에 일정하게 유지시켜 주어야 세균의 번식을 효과적으로 억제할 수 있습니다. 혈중 항생제 농도가 유지될 수 있도록 농도가 낮아질 때 항생제를 추가적으로 투약하는 방법으로 처방됩니다. 복용을 잊고 지나친 경우에는 두 배가 아닌 일 회 복용량만 복용해야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Antibiotics should be taken at regular intervals. Maintaining a consistent level of the antibiotic in the body is essential to effectively inhibit bacterial growth. Antibiotics are prescribed to ensure that their concentration remains stable in the bloodstream. If a dose is missed, take the next dose as scheduled without doubling up.
                                                        </p>

                                                        <h3 v-if="$store.state.language === 'ko'">올바른 항생제 복용법을 기억해 항생제 내성에 주의하세요.</h3>
                                                        <h3 v-if="$store.state.language === 'en'">Remember these guidelines to use antibiotics correctly and prevent antibiotic resistance.</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>


            <div v-if="currentSection === 'Lifestyle'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container image-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro" v-if="$store.state.language === 'ko'">
                                        <img src="@/assets/images/health-117.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'en'">
                                        <img src="@/assets/images/health-7.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1 v-if="$store.state.language === 'ko'">중이염을 예방하는 생활 관리</h1>
                                                        <h1 v-if="$store.state.language === 'en'">Lifestyle Management to Prevent Otitis Media</h1>

                                                        <p v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-112.png" alt="" class="responsive-image">
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            중이염을 예방하는 또 다른 방법은 생활 관리입니다. 세균이나 바이러스 감염 외에도 면역력 저하가 중이염 발병을 촉발할 수 있으므로 면역력을 높이는 영양 보충제 섭취도 권장됩니다. 또한, 적절한 예방 수칙을 준수하여 손 씻기와 같은 일상적인 위생 관리가 중요합니다. 이미 중이염에 걸린 아이들에게는 치료뿐만 아니라 이러한 생활 관리가 필수적입니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Another method to prevent otitis media is through lifestyle management. In addition to bacterial or viral infections, weakened immunity can trigger otitis media, so it is recommended to take immune-boosting supplements. Furthermore, adhering to proper preventive measures, such as regular handwashing and maintaining everyday hygiene, is essential. For children who already have otitis media, treatment along with lifestyle management is crucial.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            어떤 생활 관리가 중이염 예방과 관리에 도움이 되는지 알아보겠습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Let’s explore which lifestyle practices can help in the prevention and management of otitis media.
                                                        </p>

                                                        <h2 v-if="$store.state.language === 'ko'">중이염을 예방하는 생활 관리 두가지</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Two Preventive Lifestyle Tips</h2>

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-118.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-8.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            어린아이의 경우 젖병은 12개월까지만 사용해야 합니다. 만 1세 이후로도 젖병을 계속 빨면 이관에 가해지는 압력이 증가되어 중이염에 더 잘 걸릴 수 있습니다. 또한, 분유는 반드시 안아서 먹여야 합니다. 아이가 누워서 먹는 자세는 이관을 수평하게 만들기 때문에 분유나 이물질이 중이 안으로 더 쉽게 들어가게 됩니다. 이는 염증을 유발하므로 45˚ 정도로 기울여서 먹이는 것이 좋습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            For young children, bottles should only be used up to 12 months of age. Continuing to use bottles beyond the age of one can increase pressure on the Eustachian tubes, making children more susceptible to otitis media. Additionally, formula feeding should always be done while holding the baby. When a child drinks in a reclining position, the Eustachian tubes are more horizontal, making it easier for formula or foreign substances to enter the middle ear, potentially causing inflammation. Therefore, feeding at a 45-degree angle is recommended.
                                                        </p>

                                                        <!-- <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-119.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image"> 
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-9.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            아이를 어린이집에 오랜 시간 맡기거나 대규모 어린이집에 맡기는 것은 피하는 게 좋습니다. 많은 아이들과 접촉하게 되는 어린이집은 감기에 걸릴 확률을 높여주며, 이는 급성 중이염으로 이어질 수 있습니다. 따라서 감기에 걸릴 위험을 줄이는 것이 중요합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Avoid leaving children in daycare for extended periods or enrolling them in large daycare centers. Being in close contact with many other children increases the likelihood of catching a cold, which can lead to acute otitis media. Hence, reducing the risk of catching a cold is important.
                                                        </p> -->

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-120.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-10.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            자일리톨이 충치예방 외에 중이염을 예방할 수 있다는 연구결과도 발표되었습니다. 캐나다 토론토 대학 연구팀에서 연구논문을 분석한 결과, 자일리톨이 함유된 껌과 사탕, 시럽을 정기적으로 섭취한 아이들이 그렇지 않은 아이들에 비해 중이염에 걸릴 위험이 25% 낮은 것으로 조사되었습니다. 또한 자일리톨을 함유한 껌과 사탕이 시럽보다 중이염 예방 효과가 뛰어난 것으로 나타났지만, 이미 호흡기 질환을 겪고 있는 아이들에게는 효과가 없는 것으로 분석되었습니다. 자일리톨이 중이염 예방에 효과적인 것은 맞지만 건강한 어린이에게서만 예방 효과가 나타나기 때문에 평소 면역력을 충실히 챙겨주어야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Studies have shown that xylitol, in addition to preventing cavities, can also help prevent otitis media. Research by a team at the University of Toronto in Canada analyzed various studies and found that children who regularly consumed xylitol-containing gum, candies, or syrup had a 25% lower risk of developing otitis media compared to those who did not. The research indicated that xylitol-containing gum and candies were more effective at preventing otitis media than syrup. However, it was also found that xylitol did not benefit children who were already suffering from respiratory illnesses. While xylitol is effective in preventing otitis media, it only shows preventive effects in healthy children, so maintaining strong immunity is essential.
                                                        </p>

                                                        <h2 v-if="$store.state.language === 'ko'">중이염을 완화하는 생활 관리</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Lifestyle Management When a Child Has Otitis Media</h2>

                                                        <p style="text-align: center;" v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-121.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p style="text-align: center;" v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-11.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            아이가 중이염이 있는 상태에서 젖병이나 빨대를 사용하게 되면 오히려 중이염이 심해질 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            If a child has otitis media, using a bottle or straw can worsen the condition. It is better to use a spoon and cup.
                                                        </p>
                                                        
                                                        <p v-if="$store.state.language === 'ko'">
                                                            아이의 코가 막혔거나 콧물이 있다면 따뜻한 물수건을 코에 대거나, 콧망울 옆 주변에 있는 영향혈을 누르는 마사지를 해주면 불편한 증상이 완화되고 중이염 호전에 도움을 줄 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            If the child's nose is blocked or runny, applying a warm towel to the nose or massaging the Yingxiang point next to the nostrils can relieve discomfort and help improve otitis media.
                                                        </p>
                                                        
                                                        <p v-if="$store.state.language === 'ko'">
                                                            코를 세게 푸는 동작은 귀에 압력을 줄 수 있어 중이염에 좋지 않습니다. 또한 양쪽 코를 막고 세게 푸는 동작은 콧물 뿐 아니라 콧속 공간의 세균 바이러스가 귀로 들어가게 해서 중이염 발병의 원인이 되거나 중이염을 악화시킬 수 있습니다. 코를 풀 때는 한쪽씩 풀게 하여 귀에 가해지는 압력을 줄여야 합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Blowing the nose hard can put pressure on the ears, which is not good for otitis media. Also, blocking both nostrils and blowing hard can cause not only mucus but also bacteria and viruses in the nasal space to enter the ears, leading to or worsening otitis media. When blowing the nose, do it one side at a time to reduce the pressure on the ears.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>


            <div v-if="currentSection === 'Supplements'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'ko'">
                                        <img src="@/assets/images/health-122.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>
                                    <div class="serveices-intro image-container" v-if="$store.state.language === 'en'">
                                        <img src="@/assets/images/health-12.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1 v-if="$store.state.language === 'ko'">중이염이나 감기에 효과적인 영양제에는 어떤 것이 있을까요?</h1>
                                                        <h1 v-if="$store.state.language === 'en'">What Are Effective Supplements for Otitis Media?</h1>
                                                        <p v-if="$store.state.language === 'ko'" class="image-container">
                                                            <img src="@/assets/images/health-112.png" alt="" class="responsive-image">
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'" class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        
                                                        <h2 v-if="$store.state.language === 'ko'">면역력 증진에도 도움되는 영양제</h2>
                                                        <h2 v-if="$store.state.language === 'en'">Supplements That Help Boost Immunity</h2>
                                                        <p style="text-align: center;" class="image-container">
                                                            <img src="@/assets/images/health-13.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            오메가3 지방산은 염증을 억제하는 효과가 있으며, 특히 EPA는 염증성 물질인 PGE2를 감소시켜 중이염의 발생을 예방할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Omega-3 fatty acids have anti-inflammatory effects, and specifically, EPA can reduce the inflammatory substance PGE2, which helps prevent the occurrence of otitis media.
                                                        </p>
                                                
                                                        <p v-if="$store.state.language === 'ko'">
                                                            아연은 필수 미량 영양소이며 면역 기능 및 감염에 대한 저항성에 중요한 역할을 하고 있습니다. 특히 아연은 체내에 축적할 수 없기 때문에 정기적으로 섭취해야 합니다. 임상실험 결과에서 아연 보충제가 건강한 소아보다 중증 영양 실조 같은 면역력 저하 상태의 소아에게서 중이염 발병을 감소시키는 효과가 나타났습니다. 따라서 아연 보충제를 섭취했을 때 면역 기능이 강화되어 중이염 발생을 예방할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Zinc is an essential trace element that plays a vital role in immune function and resistance to infections. Since the body cannot store zinc, it must be consumed regularly. Clinical trials have shown that zinc supplements can reduce the incidence of otitis media in children with severe malnutrition and compromised immunity. Therefore, taking zinc supplements can enhance immune function and prevent the occurrence of otitis media.
                                                        </p>
                                                
                                                        <p v-if="$store.state.language === 'ko'">
                                                            유산균은 장내에 유익균을 증가시켜 염증물질들의 수치를 개선하고 감염을 예방할 수 있습니다. 장이 음식에 의한 자극을 지속적으로 받으면 면역력이 떨어져 알레르기 반응과 만성 중이염이 발생할 수 있습니다. 또한 개인별 알레르기 반응을 일으키는 음식 섭취 중단 시 85~93% 까지 만성 중이염 증상이 사라지고 재발률 역시 7~80% 이상 떨어지는 효과를 확인할 수 있습니다. 유산균을 섭취함으로써 장에 부담을 덜어주고 중이염을 예방할 수 있습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Probiotics increase beneficial bacteria in the gut, improve levels of inflammatory substances, and help prevent infections. Persistent stimulation of the gut by food can weaken the immune system, leading to allergic reactions and chronic otitis media. Studies have shown that eliminating foods that cause allergic reactions can reduce chronic otitis media symptoms by 85-93% and decrease recurrence rates by 70-80%. By consuming probiotics, the gut is relieved of stress, which can help prevent otitis media.
                                                        </p>
                                                        
                                                        <p v-if="$store.state.language === 'ko'">
                                                            마그네슘은 세포를 보호하고 신경을 완화시켜 이명과 어지럼증에 도움을 줍니다. 특히 스트레스 완화에 효과적입니다. 스트레스는 몸의 면역력을 떨어뜨리는 주요 원인이기 때문에 평소 마그네슘을 챙기는 것이 좋습니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            Magnesium protects cells and soothes nerves, helping with tinnitus and dizziness. It is particularly effective in relieving stress, which is a major cause of reduced immunity. Therefore, it's beneficial to regularly consume magnesium.
                                                        </p>

                                                        <p v-if="$store.state.language === 'ko'">
                                                            비타민 b군은 이명 뿐만 아니라 이명의 합병증 역시 예방할 수 있습니다. b9(엽산)는 달팽이관의 항산화 스트레스를 막아 손상으로부터 보호해 난청 위험을 낮춰줍니다. 비타민 b1, b2 그리고 b6는 귓속의 신경을 안정화시켜 이명으로 인한 어지럼증이나 귀울림을 방지합니다. 또한, 이명 환자들에게서 비타민 b12 결핍 현상이 흔히 나타나므로 충분히 보충해 주는 것이 좋습니다. 실제로 만성 이명 환자의 약 47%가 비타민 b12 결핍이었다는 연구 결과가 있습니다. 평소 채식 위주 식사를 한다면 체내 비타민 b12가 부족해질 수 있으므로 영양제로 보충하는 것이 필요합니다.
                                                        </p>
                                                        <p v-if="$store.state.language === 'en'">
                                                            The B vitamins can prevent not only tinnitus but also its complications. Folic acid (B9) prevents oxidative stress in the cochlea, protecting against damage and reducing the risk of hearing loss. Vitamins B1, B2, and B6 stabilize nerves in the ear, preventing dizziness and ringing in the ears caused by tinnitus. Vitamin B12 deficiency is common in tinnitus patients, and adequate supplementation is necessary. Research has shown that about 47% of chronic tinnitus patients have a vitamin B12 deficiency. Those who follow a vegetarian diet may lack vitamin B12, so supplementation is important.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>



        </main><!-- End #main -->

        <!-- ======= Footer ======= -->

    </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
    name: 'health',
    data() {
        return {
            currentSection: 'about' // Default section is 'about'
        };
    },
    methods: {
        showSection(section) {
            this.currentSection = section;
        },
        checkHash() {
            const hash = this.$route.hash.replace('#', '');
            if (hash) {
                this.showSection(hash);
            }
        },
        navigateToSection(section) {
            this.$router.push({ hash: `#${section}` });
        }
    },
    mounted() {
        // Swiper 초기화
        new Swiper('.swiper-container', {
            // Swiper 옵션 설정
            slidesPerView: 1,
            watchOverflow: true,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        this.checkHash(); // 초기 로드 시 해시 체크
        this.$router.afterEach(() => {
            this.checkHash(); // 라우트 변경 시 해시 체크
        });
    },
}
</script>
<style scoped>
@import url('@/assets/css/about.css');

/**
  * Template Name: Gp
  * Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
  * Updated: Mar 17 2024 with Bootstrap v5.3.3
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  */

  .image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}
.nav-btn.hidden {
    display: none;
    /* hidden 클래스를 가진 요소를 숨깁니다 */
}

.blog-section {
    padding: 40px 0;
    background: #f9f9f9;
}

.blog-post {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.blog-post h2 {
    font-size: 2em;
    margin-top: 30px;
    color: #555;
}

.blog-post p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
}

hr {
    border-top: none;
}

.nav-btn {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 2rem;
    font-family: 'Noto Sans KR', sans-serif;
}

.nav-btn button {
    font-weight: 600;
    /* 폰트 굵기 설정 */
    font-size: 15px;
    padding: 0.75rem 1.5rem;
    /* 버튼의 안쪽 여백 */
    border: none;
    /* 기본 테두리 제거 */
    background-color: #17a2b8;
    /* 배경색 설정 */
    color: #fff;
    /* 글자 색상 */
    border-radius: 5px;
    /* 모서리를 둥글게 */
    cursor: pointer;
    /* 커서를 포인터로 변경 */
    transition: background-color 0.3s ease;
    /* 배경색 전환 효과 */
}

.nav-btn button:hover {
    background-color: #138496;
    /* 호버 시 배경색 변경 */
}

.nav-btn button:focus {
    outline: none;
    /* 포커스 시 외곽선 제거 */
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
    /* 포커스 시 그림자 추가 */
}


.services-control-prev,
.services-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: none;
    border: 0;
    @include transition($carousel-control-transition);


    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }
}

.services-control-prev {
    left: 0;
    background-image: ;
}

.services-control-next {
    right: 0;
    background-image: ;
}

@media (max-width: 991px) {
    .services .image {
        max-width: 80%;
        background-size: cover;
        background-position: center;
    }

    .services .image img {
        max-width: 100%;
        background-size: cover;
        background-position: center;
    }
}

/*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
body {
    color: #444444;
}

a {
    color: #ffc451;
    text-decoration: none;
}

a:hover {
    color: #ffd584;
    text-decoration: none;
}



/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ffc451;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #151515;
    line-height: 0;
}

.back-to-top:hover {
    background: #151515;
}

.back-to-top:hover i {
    color: #ffc451;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #151515;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 0px);
    left: calc(50% - 30px);
    border: 6px solid #ffc451;
    border-top-color: #151515;
    border-bottom-color: #151515;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/

#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 9px 0;
    background: rgba(254, 254, 243, 0.8);
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgba(254, 254, 243, 0.8);
}

#header .logo {
    font-size: 32px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: #fff;
}

#header .logo a span {
    color: #ffc451;
}

#header .logo img {
    max-height: 40px;
}

/*--------------------------------------------------------------
  #  Get Startet Button
  --------------------------------------------------------------*/
.get-started-btn {
    color: #fff;
    border-radius: 4px;
    padding: 7px 25px 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    border: 2px solid #ffc451;
}

.get-started-btn:hover {
    background: #ffbb38;
    color: #343a40;
}

@media (max-width: 992px) {
    .get-started-btn {
        padding: 7px 20px 8px 20px;
        margin-right: 15px;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    /* align-items: center; */

}

.navbar li {
    /* position: relative; */
}

.navbar a,
.navbar a:focus {
    /* display: flex; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #ffc451;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #151515;
    font-weight: 400;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #151515;
    background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
    color: #151515;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 80vh;
    background: url("@/assets/images/intro-31.png") top center;
    background-size: auto 100%;
    position: relative;
}

#hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 74px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
}

#hero h1 span {
    color: #ffc451;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #ffc451;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #ffc451;
}

#hero .icon-box:hover {
    border-color: #ffc451;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #ffde9e;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Noto Sans KR', sans-serif;
    color: #151515;
}



/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features {
    padding-top: 20px;
}

.features .icon-box {
    padding-left: 15px;
}

.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.features .icon-box i {
    font-size: 48px;
    float: left;
    color: #ffc451;
}

.features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}

.features .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #ffc451;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
}

.services .icon-box .icon i {
    color: #151515;
    font-size: 28px;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #151515;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
    color: #ffc451;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    color: #151515;
    background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(21, 21, 21, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ffc451;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts .content {
    padding: 30px 0;
}

.counts .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #151515;
}

.counts .content p {
    margin-bottom: 0;
}

.counts .content .count-box {
    padding: 20px 0;
    width: 100%;
}

.counts .content .count-box i {
    display: block;
    font-size: 36px;
    color: #ffc451;
    float: left;
}

.counts .content .count-box span {
    font-size: 36px;
    line-height: 30px;
    display: block;
    font-weight: 700;
    color: #151515;
    margin-left: 50px;
}

.counts .content .count-box p {
    padding: 15px 0 0 0;
    margin: 0 0 0 50px;
    font-size: 14px;
    color: #3b3b3b;
}

.counts .content .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #3b3b3b;
    font-size: 15px;
    transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
    color: #626262;
}

.counts .image {
    background: url("@/assets/images/about-1.png") center center no-repeat;
    background-size: cover;
    min-height: 2000px;
}

@media (max-width: 991px) {
    .counts .image {
        text-align: center;
    }

    .counts .image img {
        max-width: 80%;
    }
}

@media (max-width: 667px) {
    .counts .image img {
        max-width: 100%;
    }
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
    padding: 80px 0;
    background: url("@/assets/images/intro-3.png") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
    margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    text-align: center;
    color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.6);
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
    opacity: 1;
}

@media (min-width: 1024px) {
    .testimonials {
        background-attachment: fixed;
    }
}

@media (min-width: 992px) {
    .testimonials .testimonial-item p {
        width: 80%;
    }
}


/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: whitesmoke;
    min-height: 40px;
    margin-top: 74px;
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #2f2f2f;
    content: "/";
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 68px;
    }

    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: #151515;
    border-bottom: 1px solid #222222;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
    color: #ffc451;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Noto Sans KR', sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #292929;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #ffc451;
    color: #151515;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffc451;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ffc451;
    color: #151515;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #ffcd6b;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}
</style>