import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import AOS from 'aos';
import { createRouter, createWebHistory } from 'vue-router';
import Home from "@/pages/Home";
import Product from "@/pages/Product";
import ProductDetail from "@/pages/ProductDetail";
import ProductDetail2 from "@/pages/ProductDetail2";
import ProductDetail3 from "@/pages/ProductDetail3";
import DIHApp from "@/pages/DIHApp";
import aboutDih from "@/pages/aboutDih";
import searchPill from "@/pages/searchPill";
import qna from "@/pages/qna";
import notice from "@/pages/notice";
import noticeDetail from "@/pages/noticeDetail";
import question from "@/pages/question";
import questionDetail from "@/pages/questionDetail";
import login from "@/pages/login";
import qnaWrite from "@/pages/qnaWrite";
import adminLogin from "@/adminPages/adminLogin";
import health from "@/pages/health";
import Innovation from "@/pages/Innovation";
import Exhibition from "@/pages/Exhibition";
import News from "@/pages/News";
import NewsDetail from "@/pages/NewsDetail";
import test from "@/pages/test";
import test2 from "@/pages/test2";


const routes = [
    { path: '/', component: Home, meta: { showHeaderFooter: true } },
    { path: '/Product', component: Product, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail', component: ProductDetail, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail2', component: ProductDetail2, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail3', component: ProductDetail3, meta: { showHeaderFooter: true } },
    { path: '/App', component: DIHApp, meta: { showHeaderFooter: true } },
    { path: '/about', component: aboutDih, meta: { showHeaderFooter: true } },
    { path: '/searchPill', component: searchPill, meta: { showHeaderFooter: true } },
    { path: '/qna', component: qna, meta: { showHeaderFooter: true } },
    { path: '/notice', component: notice, meta: { showHeaderFooter: true } },
    { path: '/notice/noticeDetail/:noticeNum', component: noticeDetail, meta: { showHeaderFooter: true } },
    { path: '/question', component: question, meta: { showHeaderFooter: true } },
    { path: '/question/questionDetail/:qnaNum', component: questionDetail, meta: { showHeaderFooter: true } },
    { path: '/login', component: login, meta: { showHeaderFooter: true } },
    { path: '/qnaWrite', component: qnaWrite, meta: { showHeaderFooter: true } },
    { path: '/admin', component: adminLogin, meta: { showHeaderFooter: false  } },
    { path: '/health', component: health, meta: { showHeaderFooter: true } },
    { path: '/Innovation', component: Innovation, meta: { showHeaderFooter: true } },
    { path: '/Exhibition', component: Exhibition, meta: { showHeaderFooter: true } },
    { path: '/News', component: News, meta: { showHeaderFooter: true } },
    { path: '/News/NewsDetail/:newsNum', component: NewsDetail, meta: { showHeaderFooter: true } },
    { path: '/test', component: test, meta: { showHeaderFooter: true } },
    { path: '/test2', component: test2, meta: { showHeaderFooter: true } },
]


const router = createRouter({
    history: createWebHistory(),
    routes
})

const store = createStore({
    state: {
        language: localStorage.getItem('language') || 'ko' // 로컬 스토리지에서 언어 설정을 가져옴
    },
    mutations: {
        setLanguage(state, lang) {
            state.language = lang;
            localStorage.setItem('language', lang); // 언어 설정을 로컬 스토리지에 저장
        },
    },
    actions: {
        changeLanguage({ commit }, lang) {
            commit('setLanguage', lang);
        },
    },
    getters: {
        currentLanguage: state => state.language,
    },

});

export default store;

createApp(App).use(router).use(store).mount('#app')

AOS.init();

// 초기화 함수 호출
// initializeUser();