<template>
    <div class="test">
        <p>test입니다.</p>
    </div>
</template>
<script>
export default {
  name: 'test'
}
</script>
<style scoped>

</style>