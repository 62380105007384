<template>
  <div class="aboutDih">

    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1 v-if="$store.state.language === 'ko'">닥터인홈<span>.</span></h1>
            <h1 v-if="$store.state.language === 'en'">Doctor in Home<span>.</span></h1>
            <h2 v-if="$store.state.language === 'ko'">효과적으로 아이들을 케어하세요.</h2>
            <h2 v-if="$store.state.language === 'en'">Take care of your children effectively.</h2>
          </div>
        </div>

      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- 소개 페이지 내 별도 네비게이션 / 현재 숨김 상태 / 필요시 숨김 해제해야함-->
      <nav class="nav-btn hidden">
        <button @click="showSection('about')">About</button>
        <button @click="showSection('services')">중이염</button>
        <button @click="showSection('portfolio')">편도염</button>
        <button @click="showSection('contact')">오시는 길</button>
      </nav>

      <!-- ======= About Section ======= -->
      <div v-if="currentSection === 'services'">
        <section id="services" class="services">
          <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

            <!-- <div class="row"> -->
            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
            <div class="image col-lg-6">
              <div class="services-container" data-aos="fade-right" style="text-align: center;">
                <div class="serveices-intro">
                  <img src="@/assets/images/about-51.png" alt="" style="margin-bottom: 40px;">
                  <!-- <h1>닥터인홈<span>.</span></h1> -->
                  <p v-if="$store.state.language === 'ko'">
                    중이염은 종종 '어린이 질환'으로 간주되지만, 실제로는 모든 연령대에 영향을 미칠 수 있는 질병입니다. 중이염이 무엇인지, 어떤 부작용이 있는지, 그 위험성을 소개해드리려고
                    합니다.
                  </p>
                  <p v-if="$store.state.language === 'en'">
                    Otitis media is often considered a 'child disease', but it is actually a disease that can affect all
                    ages. I would like to introduce you to what otitis media is, what side effects it has, and the
                    risks.
                  </p>
                  <img src="@/assets/images/about-52.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                  <p v-if="$store.state.language === 'ko'">중이염이 정확히 어떤 질환인지 알고 계신가요?</p>
                  <p v-if="$store.state.language === 'en'">You may have heard of otitis media, but do you know exactly
                    what it is?</p>
                  <img src="@/assets/images/about-53.png" alt="" style="margin-bottom: 40px; margin-top: 20px;"
                    v-if="$store.state.language === 'ko'">
                  <img src="@/assets/images/eng.png" alt="" style="margin-bottom: 40px; margin-top: 20px;"
                    v-if="$store.state.language === 'en'">
                  <p v-if="$store.state.language === 'ko'"><span style="font-weight: 600;">중이염이란?</span> <br>귀의 중이 부분에
                    주로 이관 기능 장애나 외부 감염으로 인해 염증이 생기는 질환입니다.
                    <br>이는 급성 중이염, 삼출성 중이염, 만성 중이염 등으로 분류될 수 있으며, <br>진행 정도에 따라 다양한 단계로 나눌 수 있습니다.
                  </p>
                  <p v-if="$store.state.language === 'en'">What is otitis media? <br>It is a disease in which
                    inflammation
                    occurs in the middle ear part of the ear, mainly due to eustachian tube dysfunction or external
                    infection. <br>It can be classified into acute otitis media, exudative otitis media, chronic otitis
                    media, etc., <br>and can be divided into various stages depending on the degree of progression.</p>
                  <br>
                  <br>
                  <h2 v-if="$store.state.language === 'ko'" style="font-weight: 600;">급성 중이염</h2>
                  <h2 v-if="$store.state.language === 'en'" style="font-weight: 600;">acute otitis media</h2>
                  <p v-if="$store.state.language === 'ko'"> 감염으로 인한 중이의 염증으로 고막 뒤에 고름이 찰 수 있습니다. 통증, 발열, 청력 감소가 흔한 증상이며,
                    주로 상기도 감염 후에 발생합니다.</p>
                  <p v-if="$store.state.language === 'en'"> Inflammation of the middle ear due to infection may cause
                    pus
                    to build up behind the eardrum. Pain, fever, and hearing loss are common symptoms and usually occur
                    after an upper respiratory tract infection.</p>
                  <br>
                  <h2 v-if="$store.state.language === 'ko'" style="font-weight: 600;">삼출성 중이염 (점액성 중이염)</h2>
                  <h2 v-if="$store.state.language === 'en'" style="font-weight: 600;">Otitis media with effusion (mucous
                    otitis media)</h2>
                  <p v-if="$store.state.language === 'ko'"> 이 형태는 중이에 점액이나 액체가 쌓이는 것이 특징이지만, 심한 통증이나 감염 징후는 적습니다. 이 상태는
                    "접착성 귀"라고도 불리며, 청력 감소를 유발할 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'"> This form is characterized by a buildup of mucus or fluid in
                    the middle ear, but causes less severe pain or signs of infection. This condition, also called “glue
                    ear,” can cause hearing loss.</p>
                  <h2 v-if="$store.state.language === 'ko'" style="font-weight: 600;">만성 중이염</h2>
                  <h2 v-if="$store.state.language === 'en'" style="font-weight: 600;">chronic otitis media</h2>
                  <p v-if="$store.state.language === 'ko'"> 중이염이 장기간 지속되거나 반복해서 발생하는 경우입니다. 고막에 구멍이 생기거나 지속적인 분비물, 청력
                    손실이
                    동반될 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'"> This is when otitis media persists for a long time or occurs
                    repeatedly. This may be accompanied by a hole in the eardrum, persistent discharge, or hearing loss.
                  </p>
                  <br>
                  <img src="@/assets/images/about-54.jpg" alt=""
                    style="margin-bottom: 40px; margin-top: 20px; width: 50%;">
                </div>
                <div id="servicesCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
                  <div class="carousel-indicators" v-if="$store.state.language === 'ko'">
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="0" class="active"
                      aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="1"
                      aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="2"
                      aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="3"
                      aria-label="Slide 4"></button>
                  </div>
                  <div class="carousel-inner" v-if="$store.state.language === 'ko'">
                    <div class="carousel-item active">
                      <img src="@/assets/images/001.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/002.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/003.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/004.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                  </div>
                  <div class="carousel-inner" v-if="$store.state.language === 'en'">
                    <div class="carousel-item active">
                      <img src="@/assets/images/eng1.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng2.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng3.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng4.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#servicesCarousel"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/prev-icon.png') + ')' }"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#servicesCarousel"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/next-icon.png') + ')' }"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

                <div class="serveices-intro">
                  <br>
                  <p v-if="$store.state.language === 'ko'">중이염을 방치하면 청력 손실을 비롯하여 <span style="font-weight: 600;">심각한 언어
                      및 학습 장애, 귀의 구조적 손상</span> 등 <br>심각한 합병증을
                    초래할 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'">If otitis media is left untreated, it can lead to hearing
                    loss,
                    <br>serious speech and learning difficulties, and structural damage to the ear.
                  </p>
                  <p v-if="$store.state.language === 'ko'">특히 어린이에게는 이러한 합병증이 <span style="font-weight: 600;">인지 및 언어
                      발달</span>에 치명적인 영향을 미칠 수 있으며, <br>성인에게도 생활의
                    질을 현저히 저하시킬 수 있습니다. 따라서 중이염의 조기 진단과 적극적인 <br>치료는 단순한 건강 관리를 넘어서 심각한 건강 문제의 예방을 위한 필수적인 조치입니다.</p>
                  <p v-if="$store.state.language === 'en'">Especially in children, these complications can have a fatal
                    impact on cognitive and language development, <br>and even in adults, they can significantly reduce
                    the quality of life. Therefore, early diagnosis and active treatment of otitis media <br>go beyond
                    simple health care and are essential measures for preventing serious health problems.</p>
                  <br>
                  <br>
                  <p v-if="$store.state.language === 'ko'">치료는 염증의 원인과 중이염의 유형에 따라 달라집니다. 일반적으로 항생제 치료, 통증 관리, 필요한 경우
                    수술적 접근이 포함됩니다. 적극적인 치료와 정기적인 검진이 중요하며, <br>이를 통해 중이염을 효과적으로 관리하고 예방할 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'">Treatment depends on the cause of the inflammation and the
                    type
                    of otitis media. Usually, antibiotic treatment, pain management, <br>and surgical approach if
                    necessary. Active treatment and regular checkups are important, <br>which can effectively manage and
                    prevent otitis media.</p>
                  <br>
                  <br>
                  <p v-if="$store.state.language === 'ko'">
                    2022년 독감 환자 수는 약 90만 명으로 보고되었으며, 코로나19로 인한 마스크 착용 해제 <br>이후인 2023년에는 약 190만 명으로 증가했습니다. 이는 중이염 환자 수와
                    비교했을 때,
                    <br>중이염도 독감 못지않게 흔한 질병임을 시사합니다. 2012년부터 2016년까지의 평균 환자 수는 약 230만 명, 2018년부터 2022년까지는 약 160만 명으로,
                    <br>중이염의 예방과 관리가 여전히 중요한 과제임을 보여줍니다.
                  </p>
                  <p v-if="$store.state.language === 'en'">
                    Flu cases were reported to be around 900,000 in 2022, rising to around 1.9 million in 2023,
                    <br>after
                    the lifting of mask-wearing due to COVID-19. This suggests that otitis media is
                    <br>as common as flu compared to the number of otitis media patients. The average number of patients
                    from 2012 to 2016 was approximately 2.3 million and from 2018 to 2022 approximately 1.6 million,
                    <br>indicating that the prevention and management of otitis media remains an important challenge.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            <div class="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-receipt"></i>
              <h4>기업 연혁</h4>
              <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-cube-alt"></i>
              <h4>Harum esse qui</h4>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-images"></i>
              <h4>Aut occaecati</h4>
              <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-shield"></i>
              <h4>Beatae veritatis</h4>
              <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
            </div>
          </div> -->
            <!-- </div> -->

          </div>

        </section><!-- End About Section -->

        <!-- ======= Clients Section ======= -->
        <!-- <section id="clients" class="clients">
      <div class="container" data-aos="zoom-in">

        <div class="clients-slider swiper">
          <div class="swiper-wrapper align-items-center">
            <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt=""></div>
          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
    </section> -->
        <!-- End Clients Section -->

        <!-- ======= Features Section ======= -->
        <!-- <section id="features" class="features">
      
    </section> -->
        <!-- End Features Section -->

        <!-- ======= Services Section ======= -->
        <section id="services" class="services">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Services</h2>
              <p v-if="$store.state.language === 'ko'">왜 중이염은 관리가 필요할까?</p>
              <p v-if="$store.state.language === 'en'">Why does otitis media need management?</p>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div class="icon-box">
                  <div class="icon"><i class='bx bxs-virus' ></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">매우 흔한 질환</a>
                    <a href="##" v-if="$store.state.language === 'en'">Very common disease</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">3세 이하에서 3명 중 2명 비율로 1회 이상, 3명 중 1명의 비율로 3회 이상 앓는 질환이다.</p>
                  <p v-if="$store.state.language === 'en'">It is a disease that affects 2 out of 3 people at least once
                    and 1 out of 3 people at least 3 times under the age of 3.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                data-aos-delay="200">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-trending-up'></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">높은 재발률</a>
                    <a href="##" v-if="$store.state.language === 'en'">High recurrence rate</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">첫 발병 시기가 2세 이전인 경우 반복적으로 급성중이염에 걸릴 확률이 높다. 중이염 치료 이후 3개월까지는
                    재발하지는 않는지 꾸준한 관찰이 필요하다.</p>
                  <p v-if="$store.state.language === 'en'">If the first onset occurs before the age of 2, there is a
                    high
                    risk of repeated acute otitis media. Continuous observation is required to check for recurrence for
                    up
                    to 3 months after treatment of otitis media.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
                data-aos-delay="300">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-brightness' ></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">재발성 중이염의 합병증</a>
                    <a href="##" v-if="$store.state.language === 'en'">Complications of recurrent otitis media</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">말하기, 언어, 인지 능력 지연 및 저하를 유발할 수 있으며, 청력 소실 혹은 손상을 초래할 수 있다.</p>
                  <p v-if="$store.state.language === 'en'">It can cause delays and decline in speech, language, and
                    cognitive abilities, and can cause hearing loss or damage.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-angry' ></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">조기치료가 필요한 고위험군</a>
                    <a href="##" v-if="$store.state.language === 'en'">High-risk group requiring early treatment</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">감각신경성 난청을 가지고 있는 경우, 교정불가능한 시각저하, 다운증후군이나 두개안면기형, 구개열, 자폐증 및
                    전반적 발달장애, 언어발달장애, 인지기능저하가 중이염과 함께 동반된 경우를 모두 포함한다.</p>
                  <p v-if="$store.state.language === 'en'">If you have sensorineural hearing loss, uncorrectable visual
                    impairment, Down syndrome or craniofacial deformity, cleft palate, autism, pervasive developmental
                    disorder, language development disorder, and cognitive decline are accompanied by otitis media.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-capsule' ></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">항생제 내성</a>
                    <a href="##" v-if="$store.state.language === 'en'">Antibiotic resistance</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">항생제 내성균에 의한 급성중이염의 발생이 증가하고 있는 국내 현실에서 항생제를 투여하지 않기 위해서는 신중한
                    경과
                    관찰이 매우 중요하다.</p>
                  <p v-if="$store.state.language === 'en'">In a domestic reality where the incidence of acute otitis
                    media
                    caused by antibiotic-resistant bacteria is increasing, careful observation is very important to
                    avoid
                    administering antibiotics.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-clinic' ></i></div>
                  <h4>
                    <a href="##" v-if="$store.state.language === 'ko'">치료되기까지</a>
                    <a href="##" v-if="$store.state.language === 'en'">Until it is cured</a>
                  </h4>
                  <p v-if="$store.state.language === 'ko'">삼출성중이염 진단 시 3개월까지 경과를 관찰하는 것이 초기처치이다. 이후에는 고막상태, 청력상태 및
                    언어발달상의
                    문제 여부를 판정하여 추가적인 치료여부를 결정해야한다.</p>
                  <p v-if="$store.state.language === 'en'">When diagnosing otitis media with effusion, the initial
                    treatment is to observe the progress for up to 3 months. Afterwards, it is necessary to determine
                    the
                    condition of the eardrum, hearing condition, and language development problems to determine whether
                    to
                    require additional treatment.</p>
                </div>
              </div>

            </div>

          </div>
        </section><!-- End Services Section -->
      </div>

      <div v-if="currentSection === 'portfolio'">
        <section id="portfolio" class="portfolio">
          <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

            <!-- <div class="row"> -->
            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
            <div class="image col-lg-6">
              <div class="services-container" data-aos="fade-right" style="text-align: center;">
                <div class="serveices-intro">
                  <img src="@/assets/images/about-21.png" alt="" style="margin-bottom: 40px;">
                  <!-- <h1>닥터인홈<span>.</span></h1> -->
                  <p v-if="$store.state.language === 'ko'">
                    중이염과 뗄 수 없는 질병이 있습니다. 바로 편도염인데요, <br>편도염에 대해서는 얼마나 알고 계신가요?
                  </p>
                  <p v-if="$store.state.language === 'en'">
                    There is an inseparable disease from otitis media. It's tonsillitis. How much do you know about
                    tonsillitis?
                  </p>

                  <p v-if="$store.state.language === 'ko'">편도염은 감기와 같은 바이러스 감염으로 인해 발생하는 경우가 많으며, 전체 환자 중 9세 이하의 어린이가 약
                    25%를 차지합니다.
                    이처럼 흔한 질병인 편도염에는 어떤 부작용이 있으며 어떻게 치료되는지를 알아보겠습니다.</p>
                  <p v-if="$store.state.language === 'en'">Tonsillitis is often caused by viral infections such as
                    colds, with children under the age of 9 accounting for about 25 percent of all patients. Let's find
                    out what side effects of tonsillitis, a common disease like this, and how it is treated.</p>
                  <!-- <img src="@/assets/images/about-53.png" alt="" style="margin-bottom: 40px; margin-top: 20px;" v-if="$store.state.language === 'ko'">
                <img src="@/assets/images/eng.png" alt="" style="margin-bottom: 40px; margin-top: 20px;" v-if="$store.state.language === 'en'"> -->
                  <img src="@/assets/images/about-22.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                  <p v-if="$store.state.language === 'ko'">편도염은 목 안쪽과 코 뒷부분에 위치한 림프 조직인 편도에 염증이 생기는 질환입니다. <br>편도는 구강과
                    비강을
                    통해 침입하는 병원균을 방어하는 중요한 역할을 하며, 세균에 대한 항체를 만들어 신체를 보호합니다. <br>편도염은 크게 급성 편도염, 만성 편도염, 그리고 편도 주위 농양으로
                    나뉩니다.</p>
                  <p v-if="$store.state.language === 'en'">Tonsillitis is a disease that causes inflammation in the
                    tonsils, which are lymphatic tissues located inside the neck and behind the nose. <br>The tonsils
                    are the mouth and the nasal cavity
                    It plays an important role in defending against pathogens invading through and protects the body by
                    creating antibodies against bacteria. <br>Tonsillitis is largely acute tonsillitis, chronic
                    tonsillitis, and peri-tonsillar abscesses
                    It's divided.</p>
                  <br>
                  <br>
                  <h2 v-if="$store.state.language === 'ko'" style="font-weight: 600;">급성 편도염</h2>
                  <h2 v-if="$store.state.language === 'en'" style="font-weight: 600;">acute tonsillitis</h2>
                  <p v-if="$store.state.language === 'ko'"> 고열, 두통, 귀 통증, 오한, 목 통증 등이 있으며, 뼈마디가 쑤시는 통증을 동반합니다. 며칠 내에 침도
                    삼킬
                    수 없을 정도로 목이 아프게 됩니다.</p>
                  <p v-if="$store.state.language === 'en'"> They include a high fever, headache, ear pain, chills, and
                    neck pain, and are accompanied by pain in the joints of the bones. Within a few days, my throat
                    hurts so much that I can't even swallow my saliva.</p>
                  <br>
                  <h2 v-if="$store.state.language === 'ko'" style="font-weight: 600;">만성 편도염</h2>
                  <h2 v-if="$store.state.language === 'en'" style="font-weight: 600;">chronic tonsillitis</h2>
                  <p v-if="$store.state.language === 'ko'"> 급성 편도염 증상이 반복적으로 나타나며, 목에 이물감이 지속됩니다. 귀 이관에 영향을 주어 <span
                      style="font-weight: 600;">중이염</span> 등 합병증을
                    동반할 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'"> Acute tonsillitis symptoms appear repeatedly, and foreign
                    body sensation persists in the neck. It can affect the ear canal and accompany back complications.
                  </p>
                  <!-- <h2 v-if="$store.state.language === 'ko'">만성 중이염</h2>
                <h2 v-if="$store.state.language === 'en'">chronic otitis media</h2>
                <p v-if="$store.state.language === 'ko'"> 중이염이 장기간 지속되거나 반복해서 발생하는 경우입니다. 고막에 구멍이 생기거나 지속적인 분비물, 청력 손실이 동반될 수 있습니다.</p>
                <p v-if="$store.state.language === 'en'"> This is when otitis media persists for a long time or occurs repeatedly. This may be accompanied by a hole in the eardrum, persistent discharge, or hearing loss.</p> -->
                  <br>
                  <img src="@/assets/images/about-24.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                </div>

                <div class="serveices-intro">
                  <br>
                  <p v-if="$store.state.language === 'ko'">편도염이 잦을 경우 다양한 부작용이 발생할 수 있습니다. <br>특히 어린 아이들에게는 <span
                      style="font-weight: 600;">성장과 기초 면역</span>에 영향을
                    미칠 수 있으며, 얼굴 <span style="font-weight: 600;">생김새</span>에도 변화를 초래할 수 있습니다.</p>
                  <p v-if="$store.state.language === 'en'">Frequent tonsillitis can cause various side effects. <br>It
                    can affect growth and basic immunity, especially in young children, and it can also lead to changes
                    in facial features.</p>
                  <p v-if="$store.state.language === 'ko'" style="font-weight: 600;">목소리에 변화가 생기거나 코골이가 발생할 수 있으며, 축농증 및
                    중이염의 위험이 증가하게 됩니다.<br>수면의 질
                    저하로 인한 성장 호르몬 분비 저하나 구강호흡으로 치열과 얼굴 생김새에 변화가 생기는 등 외적인 스트레스를 동반할 수 있습니다. <br>성인 역시 편도의 비대화로 인한 기도 협착,
                    계속되는 목 이물감 등 부작용이 발생해 불편함을 느낄 수 있으니 꼭 적절한 치료가 필요합니다.</p>
                  <p v-if="$store.state.language === 'en'">Changes in the voice or snoring can occur, and the risk of
                    sinusitis and otitis media increases. <br>It can be accompanied by external stress, such as a
                    decrease in growth hormone secretion due to a decrease in sleep quality or changes in dentition and
                    facial appearance due to oral breathing. <br>Adults also need proper treatment because they may feel
                    uncomfortable due to side effects such as airway stenosis and continuous neck foreign body due to
                    enlarged tonsils.</p>
                  <br>
                  <br>
                  <p v-if="$store.state.language === 'ko'">편도염 치료는 주로 항생제와 소염제를 사용한 약물 치료가 기본입니다. 고열이 동반되거나 합병증이 의심되는
                    경우에는
                    수액 및 항생제 투여가 필요할 수 있습니다. <br>만약 항생제 치료에 반응하지 않으면 편도 절제 수술을 고려해야 합니다. <br>특히 편도염이 아이의 성장에 지장을 주거나 치열
                    문제를 일으킬 경우 수술을 검토할 필요가 있습니다. 수술은 치료 부담과 면역 기능에 미치는 영향을 고려하여 만 3~4세 이후에 진행하는 것이 바람직합니다.</p>
                  <p v-if="$store.state.language === 'en'">Treatment of tonsillitis is mainly based on drug treatment
                    using antibiotics and anti-inflammatory drugs. If high fever is accompanied or complications are
                    suspected, fluid and antibiotic administration may be necessary. <br>If you do not respond to
                    antibiotic treatment, you should consider tonsillectomy. <br>In particular, it is necessary to
                    consider surgery if tonsillitis interferes with the child's growth or causes dental problems. It is
                    recommended that surgery be performed after the age of 3 to 4 considering the burden of treatment
                    and the impact on immune function.</p>
                  <br>
                  <br>
                  <p v-if="$store.state.language === 'ko'">
                    편도염은 흔히 발생하는 질환이지만, 특히 어린 아이들에게는 가볍게 여겨서는 안 될 문제입니다. <br>성장과 면역 기능, 그리고 얼굴 생김새에까지 영향을 미칠 수 있기 때문에 초기
                    증상이 나타날 때 적절한 치료와 관리가 필요합니다.
                    <br>아이가 자주 편도염에 걸린다면 전문가와 상의하여 적절한 치료 방안을 모색하는 것이 중요합니다.
                  </p>
                  <p v-if="$store.state.language === 'en'">
                    Tonsillitis is a common condition, but it should not be taken lightly, especially for young
                    children. <br>Since it can affect growth, immune function, and facial features, appropriate
                    treatment and management are needed when early symptoms appear.
                    <br>If your child often gets tonsillitis, it is important to consult an expert to find an
                    appropriate treatment.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            <div class="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-receipt"></i>
              <h4>기업 연혁</h4>
              <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-cube-alt"></i>
              <h4>Harum esse qui</h4>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-images"></i>
              <h4>Aut occaecati</h4>
              <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class="bx bx-shield"></i>
              <h4>Beatae veritatis</h4>
              <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
            </div>
          </div> -->
            <!-- </div> -->

          </div>

        </section><!-- End About Section -->
      </div>



      <!-- ======= Counts Section ======= -->
      <div v-if="currentSection === 'about'">
        <section id="about" class="about">
          <!-- <div class="container" data-aos="fade-up">

        <div class="row no-gutters">
          <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay="100"></div>
          <div class="col-xl-7 ps-4 ps-lg-5 pe-4 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay="100">
            <div class="content d-flex flex-column justify-content-center">
              <h3>중이염의 위험성을 경고하다</h3>
              <p>
                만 3세까지 최소 83%가 1회 이상 걸리는 중이염. 유소아 중이염은 다른 상기도 감염과는 달리 적절히 치료되지 못했을 때 발생할 수 있는 합병증 및 후유증이 있어 주의해야합니다.
              </p>
              <div class="row">
                <div class="col-md-6 d-md-flex align-items-md-stretch">
                  <div class="count-box">
                    <i class="bi bi-emoji-smile"></i>
                    <span data-purecounter-start="0" data-purecounter-end="65" data-purecounter-duration="2" class="purecounter"></span>
                    <p><strong>Happy Clients</strong> consequuntur voluptas nostrum aliquid ipsam architecto ut.</p>
                  </div>
                </div>

                <div class="col-md-6 d-md-flex align-items-md-stretch">
                  <div class="count-box">
                    <i class="bi bi-journal-richtext"></i>
                    <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="2" class="purecounter"></span>
                    <p><strong>Projects</strong> adipisci atque cum quia aspernatur totam laudantium et quia dere tan</p>
                  </div>
                </div>

                <div class="col-md-6 d-md-flex align-items-md-stretch">
                  <div class="count-box">
                    <i class="bi bi-clock"></i>
                    <span data-purecounter-start="0" data-purecounter-end="35" data-purecounter-duration="4" class="purecounter"></span>
                    <p><strong>Years of experience</strong> aut commodi quaerat modi aliquam nam ducimus aut voluptate non vel</p>
                  </div>
                </div>

                <div class="col-md-6 d-md-flex align-items-md-stretch">
                  <div class="count-box">
                    <i class="bi bi-award"></i>
                    <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="4" class="purecounter"></span>
                    <p><strong>Awards</strong> rerum asperiores dolor alias quo reprehenderit eum et nemo pad der</p>
                  </div>
                </div>
              </div>
            </div> -->
          <!-- End .content-->
          <!-- </div>
        </div> -->

          <!-- </div> -->
          <div class="container" data-aos="fade-up">

            <div class="row">
              <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <img src="@/assets/images/about.png" class="img-fluid" alt="">
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <h3 v-if="$store.state.language === 'ko'">내 아이의 건강 관리는 내 손으로. <br>닥터인홈이 함께 만들어가겠습니다.</h3>
                <h3 v-if="$store.state.language === 'en'">I take care of my child’s health in my own hands. <br>Doctor
                  in
                  Home will create it together.</h3>
                <p v-if="$store.state.language === 'ko'">
                  닥터인홈은 병원 방문 시 확인할 수 있었던 중이염 진단을 집에서도 가능하게 하기 위하여 스마트 체온계를 개발하였고, 체온계와 APP의 연동을 통해 실시간으로 아이들의 발열 및 중이염
                  상태를 확인하여 효과적으로 관리할 수 있도록 하였습니다.
                </p>
                <p v-if="$store.state.language === 'en'">
                  Doctor in Home developed a smart thermometer to enable the diagnosis of otitis media at home, which
                  could be confirmed during hospital visits, and through the linkage of the thermometer and APP, the
                  children's fever and otitis media can be checked and effectively managed in real time.
                </p>
                <ul>
                  <li v-if="$store.state.language === 'ko'"><i class="ri-check-double-line"></i> 첫 진단 후 호전되지 않는 증상에 이유도
                    모른
                    채 다시 방문해야 하는 병원.</li>
                  <li v-if="$store.state.language === 'en'"><i class="ri-check-double-line"></i> After the first
                    diagnosis, you have to visit the hospital again for symptoms that do not improve without knowing the
                    reason.</li>
                  <li v-if="$store.state.language === 'ko'"><i class="ri-check-double-line"></i> 방문하는 병원이 달라질 때마다 바뀌는
                    처방약.
                  </li>
                  <li v-if="$store.state.language === 'en'"><i class="ri-check-double-line"></i> Prescription
                    medications
                    change every time you visit a different hospital.</li>
                  <li v-if="$store.state.language === 'ko'"><i class="ri-check-double-line"></i> 중이염 증상이 나타나기 전까지 방치된 우리
                    아이.</li>
                  <li v-if="$store.state.language === 'en'"><i class="ri-check-double-line"></i> Our child was neglected
                    until symptoms of otitis media appeared.</li>
                </ul>
                <p v-if="$store.state.language === 'ko'">
                  맞벌이 많은 요즘 시대에 매일 병원을 가지 않아도, 처방제 봉투를 모아놓지 않아도, 스마트 체온계 단 하나로 손쉽게 관리할 수 있도록 만들었습니다. <br>중이염 후유증으로 고통받는
                  아이들이 사라질 때까지 닥터인홈은 노력하겠습니다.
                </p>
                <p v-if="$store.state.language === 'en'">
                  In this day and age where many people have dual incomes, we made it easy to manage your temperature
                  with
                  just a smart thermometer, without having to go to the hospital every day or collect envelopes for
                  prescriptions. <br>Doctor in Home will work hard until children suffering from the aftereffects of
                  otitis media disappear.
                </p>
              </div>
            </div>

          </div>
        </section><!-- End Counts Section -->
      </div>


      <!-- ======= Contact Section ======= -->
      <div v-if="currentSection === 'contact'">
        <section id="contact" class="contact">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Contact</h2>
              <p v-if="$store.state.language === 'ko'">오시는 길 <span style="font-size: 15px;">R&D</span></p>
              <p v-if="$store.state.language === 'en'">way to come <span style="font-size: 15px;">R&D</span></p>
            </div>

            <div>
              <iframe style="border:0; width: 100%; height: 270px;"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d395.7577601338173!2d126.87669656026587!3d37.48286135078961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61fd7c865237%3A0x4ba22bf028fb0968!2z7ISc7Jq47Yq567OE7IucIOq4iOyynOq1rCDqsIDsgrDrj5kgNDU5LTI0IDYxM-2YuA!5e0!3m2!1sko!2skr!4v1713162750497!5m2!1sko!2skr"
                frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="row mt-5">

              <div class="col-lg-4">
                <div class="info">
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4 v-if="$store.state.language === 'ko'">주소:</h4>
                    <h4 v-if="$store.state.language === 'en'">Address:</h4>
                    <p v-if="$store.state.language === 'ko'">서울특별시 금천구 가산동 459-24 o613호, 닥터인홈</p>
                    <p v-if="$store.state.language === 'en'">Gasan-dong, Geumcheon-gu, Seoul 459-24 Room o613, Doctor in
                      Home</p>
                  </div>

                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4 v-if="$store.state.language === 'ko'">이메일:</h4>
                    <h4 v-if="$store.state.language === 'en'">Email:</h4>
                    <p>otiton-medical@otiton-medical.com</p>
                  </div>

                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4 v-if="$store.state.language === 'ko'">전화:</h4>
                    <h4 v-if="$store.state.language === 'ko'">Phone:</h4>
                    <p>+82 2 6929 2688</p>
                  </div>

                </div>

              </div>

            </div>


          </div>

          <hr class="featurette-divider">

          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Contact</h2>
              <p v-if="$store.state.language === 'ko'">오시는 길 <span style="font-size: 15px;">HQ</span></p>
              <p v-if="$store.state.language === 'en'">way to come <span style="font-size: 15px;">HQ</span></p>
            </div>

            <div>
              <iframe style="border:0; width: 100%; height: 270px;"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d279.85156985052896!2d126.88160604643211!3d37.4805721224175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61db5780a2c5%3A0x7685dbe7d685fa6!2z7Jqw66a865287J207Jio7Iqk67C466asIEHrj5k!5e0!3m2!1sko!2skr!4v1717397423004!5m2!1sko!2skr"
                frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="row mt-5">

              <div class="col-lg-4">
                <div class="info">
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4 v-if="$store.state.language === 'ko'">주소:</h4>
                    <h4 v-if="$store.state.language === 'en'">Address:</h4>
                    <p v-if="$store.state.language === 'ko'">서울특별시 금천구 가산디지털1로 168 우림라이온스밸리 A동 1011호</p>
                    <p v-if="$store.state.language === 'en'">Room 1011, Building A, Woolim Lions Valley, 168 Gasan Digital 1-ro, Geumcheon-gu, Seoul</p>
                  </div>

                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4 v-if="$store.state.language === 'ko'">이메일:</h4>
                    <h4 v-if="$store.state.language === 'en'">Email:</h4>
                    <p>otiton-medical@otiton-medical.com</p>
                  </div>

                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4 v-if="$store.state.language === 'ko'">전화:</h4>
                    <h4 v-if="$store.state.language === 'ko'">Phone:</h4>
                    <p>+82 2 6929 2688</p>
                  </div>

                </div>

              </div>

            </div>


          </div>
        </section><!-- End Contact Section -->
      </div>

      <!-- ======= Cta Section ======= -->
      <section id="cta" class="cta">
        <div class="container" data-aos="zoom-in">

          <div class="text-center">
            <h3 v-if="$store.state.language === 'ko'">닥터인홈APP</h3>
            <h3 v-if="$store.state.language === 'en'">Doctor in Home APP</h3>
            <p v-if="$store.state.language === 'ko'"> 가족 구성원 모두가 기록할 수 있는 닥터인홈</p>
            <p v-if="$store.state.language === 'en'"> Doctor in Home where all family members can record</p>
            <a class="cta-btn" href="#" v-if="$store.state.language === 'ko'">설치하러 가기</a>
            <a class="cta-btn" href="#" v-if="$store.state.language === 'en'">Go to install</a>
          </div>

        </div>
      </section><!-- End Cta Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->

  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'aboutDih',
  data() {
    return {
      currentSection: 'about' // Default section is 'about'
    };
  },
  methods: {
    // 한 페이지에서 섹션으로 구분하여 선택한 섹션만 나오도록 구현
    showSection(section) {
      this.currentSection = section;
    },
    // 해당 섹션으로 이동하는 메서드
    checkHash() {
      const hash = this.$route.hash.replace('#', '');
      if (hash) {
        this.showSection(hash);
      }
    },
    // 헤더에서 해당 섹션으로 이동하는 메서드
    navigateToSection(section) {
      this.$router.push({ hash: `#${section}` });
    }
  },
  mounted() {
    // 중이염 소개 페이지의 슬라이드 구현
    // Swiper 초기화
    new Swiper('.swiper-container', {
      // Swiper 옵션 설정
      slidesPerView: 1,
      watchOverflow: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    this.checkHash(); // 초기 로드 시 해시 체크
    this.$router.afterEach(() => {
      this.checkHash(); // 라우트 변경 시 해시 체크
    });
  },
}
</script>
<style scoped>
@import url('@/assets/css/about.css');

/**
* Template Name: Gp
* Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
.nav-btn.hidden {
  display: none;
  /* hidden 클래스를 가진 요소를 숨깁니다 */
}

.nav-btn {
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  gap: 2rem;
  font-family: 'NanumSquareNeo-Variable', sans-serif;
}

.nav-btn button {
  font-weight: 600;
  /* 폰트 굵기 설정 */
  font-size: 15px;
  padding: 0.75rem 1.5rem;
  /* 버튼의 안쪽 여백 */
  border: none;
  /* 기본 테두리 제거 */
  background-color: #17a2b8;
  /* 배경색 설정 */
  color: #fff;
  /* 글자 색상 */
  border-radius: 5px;
  /* 모서리를 둥글게 */
  cursor: pointer;
  /* 커서를 포인터로 변경 */
  transition: background-color 0.3s ease;
  /* 배경색 전환 효과 */
}

.nav-btn button:hover {
  background-color: #138496;
  /* 호버 시 배경색 변경 */
}

.nav-btn button:focus {
  outline: none;
  /* 포커스 시 외곽선 제거 */
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
  /* 포커스 시 그림자 추가 */
}


.services-control-prev,
.services-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  background: none;
  border: 0;
  @include transition($carousel-control-transition);


  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

.services-control-prev {
  left: 0;
  background-image: ;
}

.services-control-next {
  right: 0;
  background-image: ;
}

@media (max-width: 991px) {
  .services .image {
    max-width: 80%;
    background-size: cover;
    background-position: center;
  }

  .services .image img {
    max-width: 100%;
    background-size: cover;
    background-position: center;
  }
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #444444;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}



/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ffc451;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}

.back-to-top:hover {
  background: #151515;
}

.back-to-top:hover i {
  color: #ffc451;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #151515;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid #ffc451;
  border-top-color: #151515;
  border-bottom-color: #151515;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 9px 0;
  background: rgba(254, 254, 243, 0.8);
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(254, 254, 243, 0.8);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #ffc451;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
#  Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  color: #fff;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #ffc451;
}

.get-started-btn:hover {
  background: #ffbb38;
  color: #343a40;
}

@media (max-width: 992px) {
  .get-started-btn {
    padding: 7px 20px 8px 20px;
    margin-right: 15px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  /* align-items: center; */

}

.navbar li {
  /* position: relative; */
}

.navbar a,
.navbar a:focus {
  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #ffc451;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #151515;
  background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  color: #151515;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  background-color: #ffc451;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 80vh;
  background: url("@/assets/images/intro-3.png") top center;
  background-size: auto 100%;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
}

#hero h1 span {
  color: #ffc451;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 8px 26px;
  position: relative;
  font-size: 17px;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: #ffc451;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding-top: 20px;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  background-color: #ddd;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  padding-top: 20px;
}

.features .icon-box {
  padding-left: 15px;
}

.features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}

.features .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ffc451;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #ffc451;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("@/assets/images/intro-3.png") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #ffc451;
  border-color: #ffc451;
  color: #151515;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(21, 21, 21, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ffc451;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .content {
  padding: 30px 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #ffc451;
  float: left;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #151515;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-size: 14px;
  color: #3b3b3b;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #626262;
}

.counts .image {
  background: url("@/assets/images/about-1.png") center center no-repeat;
  background-size: cover;
  min-height: 2000px;
}

@media (max-width: 991px) {
  .counts .image {
    text-align: center;
  }

  .counts .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("@/assets/images/intro-3.png") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
  opacity: 1;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  transition: ease-in-out 0.3s;
  color: #484848;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a:hover {
  color: #151515;
  background: #ffc451;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #151515;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  background: #ffc451;
  color: #151515;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ffc451;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #ffc451;
  border: 0;
  padding: 10px 24px;
  color: #151515;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #ffcd6b;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: whitesmoke;
  min-height: 40px;
  margin-top: 74px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #2f2f2f;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }

  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

#footer {
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #151515;
  border-bottom: 1px solid #222222;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
  color: #ffc451;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ffc451;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ffc451;
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #ffcd6b;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
</style>